import React, { Fragment, useState, useEffect } from "react";
import CommomButton from "../../../../components/common-button/index";
// import CommonPagination from "../../../../components/common-pagination/index";
import ValidationFeedback from "../../../../components/common-form-label/index";
import CommonFormLabel from "../../../../components/common-form-label/index";
import Checkbox from "../../../../components/checkbox/index";
import Input from "../../../../components/input/index";
import { Link } from "react-router-dom";

import {
  Modal,
  // ModalHeader,
  ModalBody,
  // ModalFooter,
  // Button,
} from "react-bootstrap";
import Papa from "papaparse";
import ReusableTable from "../../reusableComponent/ReusableTable";
import { useDispatch, useSelector } from "react-redux";
// import * as XLSX from "xlsx";
import { Image } from "react-bootstrap";
import { fetchCountry } from "../../../../store/eventsSlice";
import Select from "react-select";
import {
  // Add01Icon,
  ArrowRight02Icon,
  Cancel01Icon,
  Upload04Icon,
} from "../../../../icons/icons";

const InsertCsv = ({
  csvKeys,
  setCsvKeys,
  tableData,
  setTableData,
  formData,
  setFormData,
  formError,
  setFormError,
  inputdata,
  validation,
  manualText,
  setManualText,
  textAreaData,
  setTextAreaData,
  validateStep,
  setOriginalTableData,
  originalTableData,
  duplicateTableData,
  setDuplicateTableData,
  isAllowDuplicate,
  setIsAllowDuplicate,
  isAllowCountryCode,
  setIsAllowCountryCode,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [importMethod, setImportMethod] = useState("manual");
  const [step, setStep] = useState(1);
  // const [finalTableData, setFinalTableData] = useState(tableData);
  // const [originalTableData, setOriginalTableData] = useState(tableData);
  // const [duplicateTableData, setDuplicateTableData] = useState([]);
  // const [tableKeys, setTableKeys] = useState([]);
  // const [isAllowDuplicate, setIsAllowDuplicate] = useState(false);
  // const [isAllowCountryCode, setIsAllowCountryCode] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isFileImportDisabled, setIsFileImportDisabled] = useState(false);
  const [isManualImportDisabled, setIsManualImportDisabled] = useState(false);
  const [isNextButtonVisible, setIsNextButtonVisible] = useState(false); // State for showing Next button
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true); // State for enabling/disabling Next button
  // const [inputChanged, setInputChanged] = useState(false); // State to track if input is changed

  const [filename, setFilename] = useState("");

  // console.log("FINAL TABLE DATA :::::::::::::::: ", finalTableData);

  // console.log(inputChanged);

  const credits = useSelector((state) => state.auth.user.credits);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const closeModal = () => {
    // handleClearList();
    // console.log("Table Data : ", tableData);
    if (!tableData.length) {
      handleClearList();
    }
    setModalOpen(!modalOpen);
  };

  const dispatch = useDispatch();

  const [countryCodes, setCountryCodes] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState(
    formData?.countryCode || "91"
  );

  console.log("selectedCountryCode", selectedCountryCode);
  // console.log("countryCodes : : ", countryCodes);

  console.log("formData", formData);

  let item = { value: "91", label: "India (+91)" };

  useEffect(() => {
    dispatch(fetchCountry())
      .then((res) => {
        const countryCodeOptions = res.payload.map((country) => ({
          value: country.phone_code,
          label: `${country.name} (+${country.phone_code})`,
        }));
        // console.log("countryCodeOptions : :",countryCodeOptions);

        setCountryCodes(countryCodeOptions);

        if (formData?.countryCode) {
          const existingCode = countryCodeOptions.find(
            (option) => option.value === formData.countryCode
          );
          // console.log("existingCode :: ", existingCode);

          if (existingCode) {
            setSelectedCountryCode(existingCode.value);
            setIsAllowCountryCode(true);
          }
        }
      })
      .catch(() => {
        setErrorMessage("Failed to fetch country codes.");
      });
  }, [dispatch, formData, setFormData, setIsAllowCountryCode]);

  useEffect(() => {
    // Disable the Next button if country code is not selected on step 3
    if (step === 3 && !formData.countryCode) {
      setIsNextButtonDisabled(true);
    } else {
      setIsNextButtonDisabled(false);
    }
  }, [formData.countryCode, step]);

  const handleImportMethodClick = (method) => {
    if (method === "manual") {
      setIsFileImportDisabled(true);
      setIsManualImportDisabled(false);
    } else if (method === "file") {
      setIsManualImportDisabled(true);
      setIsFileImportDisabled(false);
    }
    setImportMethod(method);
    setStep(2);
    setIsNextButtonVisible(false); // Initially hide the Next button
    // setInputChanged(false); // Reset input change tracking
    toggleModal();
  };

  const handleNextStep = () => {
    // Validate country code on step 3
    // if (step === 3 && !formData.countryCode) {
    //   setErrorMessage("Country code is required.");
    //   setIsNextButtonDisabled(true);
    //   return;
    // }
    // setStep(step + 1);
    // console.log("Next Click:::::::::");
    let table_res = showManualDataInTable();
    // setTableData(finalData);
    // setCsvKeys(tableKeys);
    // console.log("cbahcbhabcbashcbjab", table_res);
    if (table_res) {
      // console.log("toggleModaltoggleModaltoggleModaltoggleModal :::", errorMessage);
      toggleModal();
    }
  };

  // const handlePrevStep = () => {
  //   setStep(step - 1);
  // };

  // const downloadSampleExcel = () => {
  //   const headers = ["Name", "Number", "Var1", "Var2"];
  //   const worksheet = XLSX.utils.json_to_sheet([{}], { header: headers });
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sample");
  //   XLSX.writeFile(workbook, "SampleTemplate.xlsx");
  // };

  const downloadSampleCsv = () => {
    const headers = ["Name", "Number", "Var1", "Var2"];
    const csvData = Papa.unparse([headers]);

    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "SampleTemplate.csv"); // Set the file name
    document.body.appendChild(link);
    link.click();

    // Clean up the URL object
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleChipClick = (code) => {
    setSelectedCountryCode(code);
    setFormData({ ...formData, countryCode: code });
  };

  const renderImportNumbers = () => (
    <>
      {importMethod === "manual" ? (
        <div>
          {/* <Input
            type="textarea"
            value={manualText}
            className="manual-textarea w-100 bg-white fs-16 floating-textarea"
            onChange={handleManualChange}
            rows={5}
            placeholder=""
            autoFocus
          /> */}
          <Input
            value={manualText}
            onChange={handleManualChange}
            placeholder="Enter Contact"
            as="textarea"
            className="shadow-none textarea-height"
          />
          <div className="d-flex align-items-center gap-2 mt-2 mb-4 manual-import-content">
            <small className="rounded-circle"></small>
            <p>
              You can add Name then comma and then Mobile like
              <span className="fw-semibold">(Name, Number,...)</span>
            </p>
          </div>

          <div className="pt-2 d-flex align-items-center justify-content-end">
            <CommomButton
              title="Next"
              onClick={handleNextStep}
              icon={
                <ArrowRight02Icon
                  width={17}
                  height={17}
                  color="#ffffff"
                  strokeWidth="2.2"
                />
              }
              className="btn-primary primary-shadow"
              subClassName="flex-row-reverse gap-2"
            />
          </div>
          {/* <Row className="d-flex justify-content-between align-items-center pt-2 pb-4 info_text">
            <Col lg="3" className="d-flex justify-content-end">
              <Button
                color="primary"
                onClick={showManualDataInTable}
                className="mx-25 d-flex align-items-center"
              >
                <Image
                  src="/images/modal-icon/eye-icon.svg"
                  alt="eye-icon"
                  width={20}
                  height={20}
                  className="me-1"
                />
                Table View
              </Button>
            </Col>
          </Row> */}
        </div>
      ) : (
        <div>
          <div className="position-relative mb-4 import-from-file">
            <div className="d-flex align-items-center flex-column justify-content-center">
              {filename ? (
                <p className="mb-3">{filename}</p>
              ) : (
                <div className="rounded-2 d-flex align-items-center justify-content-center icon mb-3">
                  <Upload04Icon
                    width={20}
                    height={20}
                    color="#6d6b77"
                    strokeWidth="2"
                  />
                </div>
              )}
              <h4 className="fw-medium text-center">Select a file to upload</h4>
              <Link to="#" className="mt-2 z-1" onClick={downloadSampleCsv}>
                Download Sample File
              </Link>
            </div>
            <Input
              type="file"
              accept=".csv"
              onChange={uploadCsv}
              className=" position-absolute top-0 bottom-0 start-0 end-0 opacity-0 z-0"
            />
          </div>
          <div className="pt-2 d-flex align-items-center justify-content-end">
            {isNextButtonVisible ? (
              <CommomButton
                title="Import"
                icon={
                  <ArrowRight02Icon
                    width={17}
                    height={17}
                    color="#ffffff"
                    strokeWidth="2.2"
                  />
                }
                className="btn-primary primary-shadow"
                subClassName="flex-row-reverse gap-2"
                disabled={isNextButtonDisabled}
                onClick={toggleModal}
              />
            ) : null}
          </div>
          {/* <Button color="link" onClick={downloadSampleCsv}>
            Download Sample File
          </Button> */}
        </div>
      )}
      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
      {/* {isNextButtonVisible && finalTableData.length > 0 && (
        <ReusableTable
          tableData={finalTableData}
          tableKey={tableKeys}
          tableScrollClass="table table-bordered"
        />
      )} */}
    </>
  );

  // const renderAddCountryCode = () => (
  //   <Fragment>
  //     <h4>Add Country Code</h4>
  //     <div className="mb-3">
  //       <Button
  //         color="primary"
  //         className="me-2"
  //         onClick={() => handleChipClick("91")} // India
  //       >
  //         India (+91)
  //       </Button>
  //       <Button
  //         color="primary"
  //         className="me-2"
  //         onClick={() => handleChipClick("1")} // USA/Canada
  //       >
  //         USA/Canada (+1)
  //       </Button>
  //       <Button
  //         color="primary"
  //         className="me-2"
  //         onClick={() => handleChipClick("44")} // UK (just as an example)
  //       >
  //         UK (+44)
  //       </Button>
  //     </div>
  //     <Select
  //       options={countryCodes}
  //       value={countryCodes.find(
  //         (option) => option.value === formData?.countryCode
  //       )}
  //       onChange={(selectedOption) => {
  //         setSelectedCountryCode(selectedOption.value);
  //         setFormData({ ...formData, countryCode: selectedOption.value });
  //       }}
  //       placeholder="Select Country Code"
  //     />
  //     <p className="text-danger">{formError.countryCode}</p>
  //   </Fragment>
  // );

  // const renderReviewDuplicates = () => (
  //   <Fragment>
  //     <h4>Review Duplicates</h4>
  //     <ReusableTable
  //       tableData={finalTableData}
  //       tableKey={tableKeys}
  //       tableScrollClass="table table-bordered"
  //     />
  //     <label for="allow-duplicates" className="form-check-label me-2">
  //       Allow Duplicates
  //     </label>
  //     <Input
  //       type="checkbox"
  //       id="allow-duplicates"
  //       checked={isAllowDuplicate}
  //       onChange={(e) => setIsAllowDuplicate(e.target.checked)}
  //     />
  //     <div className="d-flex">
  //       <ul className="d-flex mb-0 align-items-center p-0 total_data">
  //         <li className="list-unstyled">
  //           <p className="m-auto">
  //             Total:
  //             <span className="ms-1">{originalTableData?.length}</span>
  //           </p>
  //         </li>
  //         <li className="list-unstyled ms-4">
  //           <p className="m-auto">
  //             Duplicate:
  //             <span className="ms-1">{duplicateTableData?.length}</span>
  //           </p>
  //         </li>
  //       </ul>
  //     </div>
  //   </Fragment>
  // );

  const handleManualChange = (e) => {
    setManualText(e.target.value);
    setTextAreaData(e.target.value.split("\n"));
    setIsNextButtonVisible(false); // Hide Next button when user modifies data
    // setInputChanged(true); // Track that input was changed
  };

  const showManualDataInTable = () => {
    const newTableData = myNewObj(textAreaData);

    const hasInvalidData = newTableData.some(
      (item) => !item.number.trim() || !item.name.trim()
    );
    // const hasInvalidData = newTableData.some((item) => !item.name);
    if (hasInvalidData) {
      setErrorMessage("All rows must have a valid name or number.");
      setIsNextButtonDisabled(true);
      return false;
    } else {
      setErrorMessage("");
      setIsNextButtonDisabled(false); // Enable Next button if validation passes
      setIsNextButtonVisible(true); // Show the Next button after successful validation
      // return true;
    }

    if (newTableData.length > credits) {
      setErrorMessage(`You can only add up to ${credits} guests.`);
      return false;
    } else {
      setOriginalTableData(newTableData);
      // setFinalTableData(newTableData);
      setTableData(findDuplicate(newTableData, "number"));
      findDuplicate(newTableData, "number");
      setErrorMessage("");
    }
    // setInputChanged(false); // Reset input change tracking after Table View is clicked
    return true;
  };

  const myNewObj = (obj) => {
    const newRes = [];
    obj.forEach((item, index) => {
      if (item) {
        const newItem = item.split(",");
        let dataObject = {};
        let varCounter = 1;
        newItem.forEach((item, i) => {
          if (i === 0) {
            dataObject = { ...dataObject, name: item };
          } else if (i === 1) {
            dataObject = { ...dataObject, number: item };
          } else {
            dataObject = { ...dataObject, [`var${varCounter}`]: item };
            varCounter++;
          }
        });
        newRes.push(dataObject);
        if (index === 0) {
          const keys = Object.keys(dataObject);
          // setTableKeys(keys);
          setCsvKeys(keys);
        }
      }
    });
    return newRes;
  };

  const uploadCsv = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setErrorMessage("Please select a file.");
      return;
    }

    setFilename(file.name);

    try {
      Papa.parse(file, {
        complete: function (results) {
          const csvFileData = parseCsvData(results.data);
          if (!csvFileData.length) {
            setErrorMessage("No Data available. Please Choose another File");
            // event.target.files = []
            setFilename("");
            return;
          }
          if (csvFileData.length > credits) {
            // setErrorMessage(`You can only add up to ${credits} guests.`);
            setErrorMessage(`Not able to upload all contacts because of Low Credits.`);
          } else {
            setOriginalTableData(csvFileData);
            // setFinalTableData(csvFileData);
            setTableData(findDuplicate(csvFileData, "number"));
            findDuplicate(csvFileData, "number");
            setFormData({ ...formData, countryCode: "91" });
            // setTableKeys(Object.keys(csvFileData[0]));
            setCsvKeys(Object.keys(csvFileData[0]));
            setErrorMessage("");
            setIsNextButtonVisible(true); // Show the Next button after successful file upload
            setIsNextButtonDisabled(false); // Enable Next button after file upload
          }
        },
        error: () => {
          setErrorMessage(
            "Error parsing CSV file. Please ensure it is formatted correctly."
          );
        },
      });
    } catch (error) {
      setErrorMessage("An unexpected error occurred while uploading the file.");
    }
  };

  const parseCsvData = (data) => {
    const newDataArray = data.map((item) => item.toString());
    let firstRow = newDataArray[0].split(",");
    if (
      firstRow[0].toLowerCase() === "name" &&
      firstRow[1].toLowerCase() === "number"
    ) {
      newDataArray.shift();
    }
    return newDataArray
      .map((item) => {
        const [name, number, ...vars] = item.split(",");
        if (name && number) {
          return {
            name,
            number,
            ...vars.reduce((acc, v, i) => ({ ...acc, [`var${i + 1}`]: v }), {}),
          };
        }

        return null;
      })
      .filter(Boolean);
  };

  const findDuplicate = (arrayOfObj, key) => {
    try {
      const grouped = groupBy(arrayOfObj, key);
      const output = [];
      const duplicateArray = [];

      Object.keys(grouped).forEach((key) => {
        const out = grouped[key].reduce((acc, current) => {
          duplicateArray.push(acc);
          return { ...current };
        });
        output.push(out);
      });

      setDuplicateTableData(duplicateArray);
      return output;
    } catch (error) {
      setErrorMessage("Error finding duplicates.");
    }
  };

  const groupBy = (arr, property) => {
    return arr.reduce((memo, x) => {
      if (!memo[x[property]]) {
        memo[x[property]] = [];
      }
      memo[x[property]].push(x);
      return memo;
    }, {});
  };

  const handleClearList = () => {
    setOriginalTableData([]);
    // setFinalTableData([]);
    setDuplicateTableData([]);
    setTableData([]);
    setCsvKeys([]);
    // setTableKeys([]);
    setManualText("");
    setTextAreaData([]);
    setFormData({ ...formData, countryCode: "91" });
    setErrorMessage("");
    setIsAllowDuplicate(false);
    setIsFileImportDisabled(false); // Enable the file import method
    setIsManualImportDisabled(false); // Enable the manual import method
    setSelectedCountryCode("91");
    setIsNextButtonVisible(false); // Hide the Next button on clear
    setIsNextButtonDisabled(true); // Disable Next button on clear
    setFilename("");
  };

  const handleEditClick = () => {
    if (importMethod === "manual") {
      setStep(2); // Set the step to manual import
    } else if (importMethod === "file") {
      setStep(2); // Set the step to file import
    }
    toggleModal(); // Open the modal
  };

  const renderCurrentStep = () => {
    switch (step) {
      case 2:
        return renderImportNumbers();
      // case 3:
      //   return renderAddCountryCode();
      // case 4:
      //   return renderReviewDuplicates();
      default:
        return null;
    }
  };

  return (
    <>
      <div className="bg-white rounded-2 overflow-hidden common-shadow p-4 mb-4">
        <div className="import-number-content">
          <div className="import-number-header mb-4">
            <h5 className="fw-medium mb-1">Import CSV File Or Manual Data</h5>
            <p>Select what method for import contacts</p>
          </div>
          {tableData?.length === 0 && (
            <div className="d-flex align-items-center gap-4">
              <CommomButton
                title="Manual Import"
                onClick={() => handleImportMethodClick("manual")}
                disabled={isManualImportDisabled}
                className="btn-primary primary-shadow "
              />

              <CommomButton
                title="Import from File"
                onClick={() => handleImportMethodClick("file")}
                disabled={isFileImportDisabled}
                className="btn-primary primary-shadow "
              />
            </div>
          )}
        </div>
      </div>

      <div className="bg-white rounded-2 overflow-hidden common-shadow  d-flex flex-column justify-content-between mb-4">
        <div>
          <div className="p-4 d-flex align-items-center justify-content-between gap-4">
            <h5 className="fw-medium text-capitalize table-title">Details</h5>
            <div className="d-flex align-items-center gap-3">
              <CommomButton
                disabled={tableData.length === 0}
                onClick={handleEditClick}
                title="Edit"
                className="btn-primary"
              />

              <CommomButton
                onClick={handleClearList}
                disabled={tableData.length === 0}
                title="Clear Data"
                className="btn-outline-danger bg-transparent"
              />
            </div>
          </div>
          <ReusableTable tableData={originalTableData} tableKey={csvKeys} />
        </div>

        {/* Pagination */}
        <div className="p-3 mx-1 d-flex flex-wrap align-items-center justify-content-between gap-4">
          <div>
            {/* {totalPages > 1 ? (
              <CommonPagination
                totalItems={total}
                itemsPerPage={limit}
                currentPage={currentPage + 1}
                onPageChange={handlePageChange}
              />
            ) : null} */}
          </div>
        </div>
      </div>

      <div className="bg-white rounded-2 common-shadow p-4 mb-4">
        <div className="import-number-content">
          <div className="import-number-header mb-4">
            <h5 className="fw-medium mb-1">Add Country Code</h5>
            <div className=" rounded-2 d-flex align-items-center justify-content-start gap-2 mt-3 allow-duplicates-contnt">
              <Checkbox
                id="allow-country-code"
                name="allow-country-code"
                checked={isAllowCountryCode}
                onChange={(e) => {
                  console.log("Checked status : ", e.target.checked);
                  if (!e.target.checked) {
                    console.log("False Case ::: ");
                    handleChipClick("");
                  }
                  setIsAllowCountryCode(e.target.checked);
                  console.log("Formdata for country code :", formData);
                }}
                className="md-checkbox"
              />
              <p>Add Country Code</p>
            </div>
          </div>

          {isAllowCountryCode ? (
            <>
              {" "}
              <div className="d-flex align-content-center gap-3 justify-content-start mb-3">
                <CommomButton
                  title="+91"
                  icon={
                    <Image
                      src="/assets/images/flag/small/india.svg"
                      alt="INDIA-Flag"
                    />
                  }
                  onClick={() => handleChipClick("91")}
                  className={`btn-outline-primary ${
                    selectedCountryCode === "91"
                      ? "bg-primary text-white"
                      : "bg-transparent"
                  }`}
                  subClassName="fw-medium gap-2"
                />
                <CommomButton
                  title="+1"
                  icon={
                    <Image
                      src="/assets/images/flag/small/usa.svg"
                      alt="USA-Flag"
                    />
                  }
                  onClick={() => handleChipClick("1")}
                  className={`btn-outline-primary ${
                    selectedCountryCode === "1"
                      ? "bg-primary text-white"
                      : "bg-transparent"
                  }`}
                  subClassName="fw-medium gap-2"
                />
                <CommomButton
                  title="+44"
                  icon={
                    <Image
                      src="/assets/images/flag/small/uk.svg"
                      alt="UK-Flag"
                    />
                  }
                  onClick={() => handleChipClick("44")}
                  className={`btn-outline-primary ${
                    selectedCountryCode === "44"
                      ? "bg-primary text-white"
                      : "bg-transparent"
                  }`}
                  subClassName="fw-medium gap-2"
                />
              </div>
              <div>
                <CommonFormLabel title="Country Code" />
                <Select
                  options={countryCodes}
                  value={
                    countryCodes
                      ? countryCodes.find(
                          (option) => option.value === selectedCountryCode
                        )
                      : item
                  }
                  className="custom-select"
                  onChange={(selectedOption) => {
                    console.log("selectedOption : : ", selectedOption);

                    setSelectedCountryCode(selectedOption.value);
                    setFormData({
                      ...formData,
                      countryCode: selectedOption.value,
                    });
                  }}
                  placeholder="Select Country Code"
                />
                <ValidationFeedback title={formError.countryCode} />
              </div>{" "}
            </>
          ) : null}
        </div>
      </div>

      <div className="bg-white rounded-2 common-shadow p-4 mb-4">
        <div className="import-number-content">
          <div className="import-number-header mb-4">
            <h5 className="fw-medium mb-1">Review Duplicates</h5>
          </div>
          <div className="d-flex align-items-center gap-4">
            <div className="d-flex align-items-start gap-2 me-2 pe-1 reiview-duplicates-content">
              <span className="rounded-circle bg-dot-purple"></span>
              <div>
                <p className="fw-medium">Total Contact</p>
                <h5 className="fw-medium mt-1">{originalTableData.length}</h5>
              </div>
            </div>
            <div className="v-divider"></div>
            <div className="d-flex align-items-start gap-2 ms-2 ps-1 reiview-duplicates-content">
              <span className="rounded-circle bg-dot-red"></span>
              <div>
                <p className="fw-medium">Duplicates Contact</p>
                <h5 className="fw-medium mt-1">{duplicateTableData.length}</h5>
              </div>
            </div>
          </div>

          <div className=" rounded-2 d-flex align-items-center justify-content-start gap-2 mt-3 allow-duplicates-contnt">
            <Checkbox
              id="allow-duplicates"
              name="allow-duplicates"
              checked={isAllowDuplicate}
              onChange={(e) => {
                let uniqueData = [
                  ...new Map(
                    originalTableData.map((item) => [item.number, item])
                  ).values(),
                ];

                const finalData = !isAllowDuplicate
                  ? originalTableData // Include both unique and duplicate entries
                  : uniqueData; // Only include unique entries

                console.log(
                  "is Allow ::::: ",
                  isAllowDuplicate,
                  "  ::: final Table ::: ",
                  finalData
                );
                setTableData(finalData);
                setIsAllowDuplicate(e.target.checked);
              }}
              className="md-checkbox"
            />
            <p>Allow Duplicates</p>
          </div>
        </div>
      </div>

      {/* <Row>
        <Col
          xl="12"
          className="d-flex align-items-center justify-content-between mb-4"
        >
          {tableData.length !== 0 && (
            <div className="">
              <Button
                color="warning"
                className="me-2"
                disabled={tableData.length === 0}
                onClick={handleEditClick}
              >
                Edit
              </Button>
              <Button
                color="danger"
                onClick={handleClearList}
                disabled={tableData.length === 0}
              >
                Clear Data
              </Button>
            </div>
          )}
        </Col>
        {tableData?.length !== 0 && (
          <Col xl="12" className="">
            <div className="bg-white">
              <ReusableTable
                tableData={tableData || []}
                tableKey={csvKeys || []}
                tableScrollClass={"tablescrollMain table-bordered"}
              />
            </div>
          </Col>
        )}
      </Row> */}

      {/* Modal for the step-by-step process */}
      <Modal
        show={modalOpen}
        size="lg"
        centered
        dialogClassName="common-popup-dialog"
        contentClassName="common-popup-content p-4"
        backdropClassName="common-popup-backdrop"
      >
        {/* <ModalHeader toggle={closeModal}>
          Step {step}:{" "}
          {step === 2
            ? "Import Numbers"
            : step === 3
              ? "Add Country Code"
              : "Review Duplicates"}
        </ModalHeader> */}
        <ModalBody className="common-popup-body">
          <div
            className="position-absolute end-0 z-2 bg-white rounded-1 cursor-pointer d-flex align-items-center justify-content-center  common-popup-colose-btn"
            onClick={closeModal}
          >
            <Cancel01Icon
              width={16}
              height={16}
              color="#BCBBC1"
              strokeWidth="2.5"
            />
          </div>
          <div className="mb-4 text-center common-popup-heading">
            <h4 className="text-capitalize mb-2 fw-medium">
              {importMethod === "manual"
                ? "Manual Import "
                : "Import From File"}
            </h4>
            <p>
              {importMethod === "manual"
                ? "Enter manual number or import "
                : "Select csv file or import"}
            </p>
          </div>
          {renderCurrentStep()}
        </ModalBody>
        {/* <ModalFooter className="p-0 border-0">
          {step > 2 && (
            <Button color="secondary" onClick={handlePrevStep}>
              Previous
            </Button>
          )}
          {isNextButtonVisible && step < 4 && (
            <Button
              color="primary"
              onClick={handleNextStep}
              disabled={isNextButtonDisabled}
            >
              Next
            </Button>
          )}

          {step === 4 && (
            <Button
              color="primary"
              onClick={() => {
                let uniqueData = [
                  ...new Map(
                    finalTableData.map((item) => [item.number, item])
                  ).values(),
                ];
                const finalData = isAllowDuplicate
                  ? finalTableData // Include both unique and duplicate entries
                  : uniqueData; // Only include unique entries
                toggleModal();
                setTableData(finalData);
                setCsvKeys(tableKeys);
              }}
            >
              Import
            </Button>
          )}
        </ModalFooter> */}
      </Modal>
    </>
  );
};

export default InsertCsv;
