import React, { useEffect, useState, useCallback } from "react";
import Widget from "../../../components/widget/index";
import NavTabs from "../../../components/nav-tabs/index";
import Input from "../../../components/input/index";
import CommomButton from "../../../components/common-button/index";
import StatusChip from "../../../components/status-chip/index";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip as TT } from "react-bootstrap";
import {
  Table,
  // Button,
  Toast,
  // Spinner,
  // Tabs,
  // Tab,
  Card,
  // ListGroup,
  // ListGroupItem,
  ToastContainer,
  Image,
  OverlayTrigger,
  Modal,
  // Modal,
} from "react-bootstrap";
import {
  fetchCampaignDetail,
  updateGuestStatus,
  fetchAllCampaignDetail,
  fetchCampaignById,
  cloneCampaign,
} from "../../../store/eventsSlice";
import { Container, Col, Row } from "react-bootstrap";
import "../../pages/eventsDetails/styleModule.css";
import { useNavigate, useParams } from "react-router-dom";
// import SortImage from "../../../sort.svg";
// import PaginationComponent from "../pagination/pagination";
import CommonLayout from "../../layouts/master/index";
import { useLoader } from "../../../context/LoaderContext";
import moment from "moment";
import * as XLSX from "xlsx";
// import { ArrowLeft } from "../../../constants/icons/icons";
import axios from "axios";
import { pdfjs, Document, Page } from "react-pdf";
// import { RefreshCcw, ChevronLeft, ChevronRight } from "react-feather";
import {
  Calendar03Icon,
  SentIcon,
  UserIcon,
  MessageSearch01Icon,
  MessageDone01Icon,
  MessagePreview01Icon,
  MessageCancel01Icon,
  Message01Icon,
  MessageQuestionIcon,
  // Add01Icon,
  MessageUpload01Icon,
  // RefreshIcon,
  ReloadIcon,
  ArrowLeftIcon,
  CheckmarkBadge04Icon,
  // Cancel01Icon,
  Cancel01Icon,
  FileDownloadIcon,
  // FileDownloadIcon,
  // DelayFromIcon,
  // ArrowRightIcon,
} from "../../../icons/icons";
import { CampaignsDataTabs } from "../../../constants/pages/campaign/detail/index";
import BackPageTab from "../../../components/back-page-tab";
import CommonPagination from "../../../components/common-pagination";
import Zoom from "react-medium-image-zoom"; // For image zooming
import "react-medium-image-zoom/dist/styles.css"; // Image zooming styles
import PdfZoomViewModal from "./PdfZoomViewModal";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { PDFDocument, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import CreatableSelect from "react-select/creatable";
import { recordsPerPageOptions } from "../campaings/common/Common";

let ws;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function CampaignDetails() {
  const dispatch = useDispatch();
  const { setLoading, loading } = useLoader();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("toast-success");

  const campaingDetailsData = useSelector(
    (state) => state.events?.campaingDetailsData
  );
  const total = useSelector((state) => state.events?.total);
  const totalPages = useSelector((state) => state.events?.totalPages);
  const campaingDetails = useSelector((state) => state.events?.campaingDetails);

  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState({ value: 50, label: 50 });
  const [searchTerm, setSearchTerm] = useState("");
  const [documentUrl, setDocumentUrl] = useState(null);
  // const [pdfTotalPages, setPdfTotalPages] = useState(0);
  const [pdfCurrentPage, setPdfCurrentPage] = useState(1);

  // const [activeTab, setActiveTab] = useState("list");
  const [isFailedSelected, setIsFailedSelected] = useState(false);
  const [isInvalidSelected, setIsInvalidSelected] = useState(false);
  const [activeSubTab, setActiveSubTab] = useState("guests");
  // const [isLoading, setIsLoading] = useState(false);

  const [activeTabStatus, setActiveTabStatus] = useState("all");

  // PDF Viewer
  // const [isZoomed, setIsZoomed] = useState(false); // Zoom state
  // const [numPages, setNumPages] = useState(null); // Track the number of PDF pages

  const navigate = useNavigate();
  const { id } = useParams();

  const fetchDocument = useCallback(
    async (url) => {
      try {
        setLoading(true);
        // 1728043874
        const response = await axios.get(url, { responseType: "blob" });
        const blobUrl = URL.createObjectURL(response.data);
        setDocumentUrl(blobUrl);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching document: ", error);
        setLoading(false);
      }
    },
    [setLoading]
  );

  const fetchCampaignDetailData = useCallback(() => {
    // if (activeTab === "list") {
    setIsFailedSelected(false);
    setIsInvalidSelected(false);
    setLoading(true);
    dispatch(
      fetchCampaignDetail({
        offset: currentPage,
        limit: limit?.value,
        search: searchTerm,
        is_test: activeSubTab === "guests" ? "false" : "true",
        id: id,
      })
    ).finally(() => {
      setLoading(false);
    });
    // }
  }, [
    dispatch,
    currentPage,
    limit?.value,
    searchTerm,
    id,
    activeSubTab,
    setLoading,
    // activeTab,
  ]);

  const fetchCampaignByIdData = useCallback(() => {
    // if (activeTab === "details") {
    setLoading(true);
    dispatch(fetchCampaignById({ id }))
      .then((response) => {
        const { campaingDetails } = response.payload;
        if (campaingDetails && campaingDetails?.new_template_url) {
          fetchDocument(campaingDetails.new_template_url);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // }
  }, [dispatch, id, setLoading, fetchDocument]);

  useEffect(() => {
    fetchCampaignDetailData();
  }, [fetchCampaignDetailData]);

  useEffect(() => {
    fetchCampaignByIdData();
  }, [fetchCampaignByIdData]);

  const handleStatusClick = (status) => {
    setActiveTabStatus(status);
    setLoading(true);
    setIsFailedSelected(status === "failed");
    setIsInvalidSelected(status === "invalid");
    const fetchParams = {
      offset: currentPage,
      limit: limit?.value,
      search: searchTerm,
      is_test: false,
      id: id,
    };

    if (status !== "all") {
      fetchParams.status = status;
    }

    dispatch(fetchCampaignDetail(fetchParams)).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    ws = new WebSocket(process.env.REACT_APP_SOCKET_URL);

    ws.onopen = () => {
      const wsId = `cmd_${id}`;
      ws.send(JSON.stringify({ type: "REGISTER", id: wsId }));
      // setSock(ws);
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const { status, guest, reason } = data;
      dispatch(updateGuestStatus({ id: parseInt(guest.id), status, reason }));
      setSnackbarMessage(`Message to ${guest.mobile_number} ${status}`);
      setSnackbarVariant(status === "sent" ? "toast-success" : "toast-danger");
      setShowSnackbar(true);
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    ws.onclose = () => {
      setTimeout(() => {
        ws = new WebSocket(process.env.REACT_APP_SOCKET_URL);
      }, 1000);
    };

    return () => {
      ws.close();
    };
  }, [dispatch, id]);

  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber - 1);
  };

  const handleBackClick = () => {
    const clientId = campaingDetailsData?.data[0]?.client_id;

    if (clientId) {
      const targetPath = `/customers/${clientId}`;
      // console.log("Navigating to:", targetPath);
      navigate(targetPath, { replace: true });
      window.location.href = targetPath; // Fallback to ensure navigation
    } else {
      console.error("Client ID not found, unable to navigate.");
      navigate(-1); // Go back one step if clientId is not available
    }
  };

  const handleRefreshClick = () => {
    // window.location.reload();
    setLoading(true);
    dispatch(fetchCampaignDetail({ id: id })).finally(() => {
      setLoading(false);
    });
  };

  // console.log("campaingDetails :: ", campaingDetails);

  // const handleTabSelect = (key) => {
  //   setActiveTab(key);
  //   setCurrentPage(0);
  // };

  const handleExportClick = async () => {
    try {
      setLoading(true);
      const allDataResponse = await dispatch(
        fetchAllCampaignDetail({
          offset: currentPage,
          limit: campaingDetailsData?.total_recipients,
          search: searchTerm,
          is_test: "false",
          id: id,
        })
      ).unwrap();

      const dataToExport = allDataResponse?.campaingDetailsData?.data?.map(
        (event, index) => ({
          Name: event?.name,
          Mobile: event?.mobile_number,
          Date: new Date(event?.campaign.created_at).toLocaleString("en-IN", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }),
          ScheduleDate: event?.campaign.scheduler_date
            ? moment(event?.campaign?.scheduler_date).format(
                "DD/MM/YYYY hh:mm A"
              )
            : "-",
          Status: event?.status !== "pending" ? event.status : "Pending",
          FailedReason: event?.failed_reason || "-",
          CreditsUsed: event?.credits,
          SentStatus: event?.whatsapp_sent
            ? new Date(event?.whatsapp_sent * 1000).toLocaleString("en-IN", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })
            : "-",
          DeliveryStatus: event?.whatsapp_deliver
            ? new Date(event?.whatsapp_deliver * 1000).toLocaleString("en-IN", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })
            : "-",
          SeenStatus: event?.whatsapp_seen
            ? new Date(event?.whatsapp_seen * 1000).toLocaleString("en-IN", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })
            : "-",
        })
      );

      const worksheet = XLSX.utils.json_to_sheet(dataToExport);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Campaign Details");

      XLSX.writeFile(workbook, "campaign_details.xlsx");
      setLoading(false);
    } catch (error) {
      console.error("Error exporting data: ", error);
      setLoading(false);
    }
  };

  const handleResendFailedClick = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${process.env.REACT_APP_WHATSAPP_API_URL}/api/whatsapp/resend-failed`,
        {
          clientId: campaingDetailsData?.data[0]?.client_id,
          wsId: "cmd",
          campaignId: parseInt(id),
          fileUrl:
            campaingDetailsData?.data[0]?.campaign?.template_type !== "text"
              ? `${process.env.REACT_APP_API_URL}/storage/${campaingDetailsData?.data[0]?.campaign?.template_url}`
              : null,
          companyId: campaingDetailsData?.data[0]?.company_id,
        }
      );
      setSnackbarMessage("Resending failed messages started successfully");
      setSnackbarVariant("toast-success");
      setShowSnackbar(true);
    } catch (error) {
      setSnackbarMessage("Failed to start resending messages");
      setSnackbarVariant("toast-danger");
      setShowSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleInvalidClick = async () => {
    try {
      setLoading(true);
      dispatch(cloneCampaign({ id: id }))
        .then((response) => {
          console.log("Response :::: ", response);
          if (response && response.payload && response.payload.success) {
            setSnackbarMessage("New Campaign Created Successfully!");
            setSnackbarVariant("toast-success");
            setShowSnackbar(true);
            // navigate(``)
            handleModalClose();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setSnackbarMessage("Failed to Create New Campaig");
      setSnackbarVariant("toast-danger");
      setShowSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    // setPdfTotalPages(numPages);
    setPdfCurrentPage(1);
    // setNumPages(numPages);
  };

  const handleSubTabSelect = (key) => {
    setActiveSubTab(key);
    setCurrentPage(0);
  };

  const getUserInitial = (name) => {
    return name
      ? name
          .split(" ")
          .map((n) => n.charAt(0))
          .join("")
      : "UN";
  };

  const titles = [{ name: "Customers", path: "/customers" }];

  // Toggle zoom state
  // const handleZoomToggle = () => {
  //   setIsZoomed((prev) => !prev);
  // };

  // Close zoom when clicking outside
  // const handleClickOutside = () => {
  //   if (isZoomed) {
  //     setIsZoomed(false);
  //   }
  // };

  const [showAddModal, setShowAddModal] = useState(false);

  const handleModalClose = () => {
    setShowAddModal(false);
  };

  const handleOpenModal = () => {
    setShowAddModal(true);
  };

  const [openPdfModalView, setOpenPdfModalView] = useState(false);
  const [pdfPageWiseView, setPdfPageWiseView] = useState([]);

  // Handler for opening the Pdf Zoom View
  const handleOpenPdfView = async () => {
    if (campaingDetails?.template_type === "pdf") {
      const url = campaingDetails?.new_template_url; // API-provided PDF URL
      // Convert PDF to page-wise images or load directly
      processPdf(url);
    }
    await new Promise(() =>
      setTimeout(() => {
        setOpenPdfModalView(true);
      }, 1000)
    );
  };

  const processPdf = async (pdfUrl) => {
    // Example logic using react-pdf (or PDF.js for advanced splitting):
    const pdfjsLib = window.pdfjsLib;
    const pdfDoc = await pdfjsLib.getDocument(pdfUrl).promise;

    // Extract each page as an image and store in pdfPageWiseView
    const pageImages = [];
    for (let i = 1; i <= pdfDoc.numPages; i++) {
      const page = await pdfDoc.getPage(i);
      const viewport = page.getViewport({ scale: 1 });
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      await page.render({ canvasContext: context, viewport }).promise;
      const base64Image = canvas.toDataURL("image/png");
      pageImages.push({
        base64: base64Image,
        width: viewport.width,
        height: viewport.height,
      });
    }
    if (pageImages.length > 0) {
      setPdfPageWiseView(pageImages); // Set page-wise view
    }
  };

  // Handler for closing the Pdf Zoom View
  const handleClosePdfViewModal = () => {
    setOpenPdfModalView(false);
  };

  // const changeFont = async (data) => {
  //   console.log("Datat :::: ", data);
  //   let pos = campaingDetails?.variable_positions;

  //   const existingPdfBytes = await fetch(
  //     campaingDetails?.new_template_url
  //     // "http://localhost:8000/storage/Campaigns/1732616305.pdf"
  //     // http://localhost:8000/storage/Campaigns/1732529749.jpg
  //   ).then((res) => res.arrayBuffer());

  //   const pdfDocs1 = await PDFDocument.load(existingPdfBytes);
  //   console.log("pdfDocs1", pdfDocs1);

  //   const pages = pdfDocs1.getPages();
  //   pdfDocs1.registerFontkit(fontkit);
  //   let embeddedFonts = [];
  //   for (let key in pos) {
  //     const fontObject = pos[key];

  //     console.log("FontObject :::: ", fontObject);
  //     const fontBytes = await fetch(fontObject.fontFile).then((res) =>
  //       res.arrayBuffer()
  //     );

  //     console.log(" fontBytes ::::: ", fontBytes);
  //     await fontkit.create(fontBytes);
  //     const embeddedFont = await pdfDocs1.embedFont(fontBytes, {
  //       subset: true,
  //     });
  //     embeddedFonts.push(embeddedFont);

  //     console.log("embeddedFonts ::::: ", embeddedFonts);
  //   }

  //   let index = 0;
  //   for (let key in pos) {
  //     const page = pages[pos[key].page - 1];
  //     const {
  //       // width,
  //       height,
  //     } = page.getSize();
  //     // const textWidth = embeddedFonts[index]?.widthOfTextAtSize(
  //     //   data[key],
  //     //   pos[key]?.size
  //     // );
  //     const textHeight = embeddedFonts[index].sizeAtHeight(pos[key]?.size);
  //     let x = pos[key].x;
  //     console.log("COLOR HHAHAHHAHAH:::: ", textHeight);
  //     page.drawText(data[key].replaceAll(/;/g, ","), {
  //       x: x,
  //       y:
  //         height -
  //         (pos[key].y +
  //           textHeight -
  //           (textHeight >= 46
  //             ? 7
  //             : textHeight >= 30
  //             ? 6
  //             : textHeight >= 12
  //             ? 3
  //             : 1)),
  //       size: pos[key]?.size,
  //       font: embeddedFonts[index],
  //       color: rgb(
  //         pos[key]?.color[0] / 255,
  //         pos[key]?.color[1] / 255,
  //         pos[key]?.color[2] / 255
  //       ),
  //     });
  //     index++;
  //   }
  //   const pdfBytes = await pdfDocs1.save();
  //   return pdfBytes;
  // };

  // // Download Failed Campaigns (Pdf or Image)
  // const downloadFailedZip = async (failedList, type) => {
  //   // setLoading(true);
  //   if (!failedList || failedList.length === 0) {
  //     alert("No failed campaigns to download");
  //     return;
  //   }

  //   const zip = new JSZip();
  //   for (let i = 0; i < failedList.length; i++) {
  //     const entry = failedList[i];

  //     // console.log("entry",entry);

  //     // Create PDF for each failed entry
  //     const pdfBytes = await changeFont({
  //       ...failedList[i]?.variables,
  //       name: entry?.name,
  //     });
  //     console.log("pdfBytes", pdfBytes);

  //     // // Add the generated PDF to the ZIP archive
  //     const fileName = `${entry.name || `failed-${i + 1}`}.pdf`;
  //     zip.file(fileName, pdfBytes);
  //   }
  //   // Generate the ZIP file
  //   zip.generateAsync({ type: "blob" }).then((content) => {
  //     saveAs(content, "failed-list.zip");
  //   });
  // };
  // Utility to check if the URL is an image
  const isImageFile = (url) => {
    const imageExtensions = [".jpeg", ".jpg", ".png"];
    return imageExtensions.some((ext) => url.toLowerCase().endsWith(ext));
  };

  const changeFont = async (data) => {
    console.log("Data :::: ", data);
    let pos = campaingDetails?.variable_positions;

    console.log("pos : : : : : :", pos);

    const existingPdfBytes = await fetch(
      campaingDetails?.new_template_url
    ).then((res) => res.arrayBuffer());

    const pdfDocs1 = await PDFDocument.load(existingPdfBytes);
    console.log("pdfDocs1", pdfDocs1);

    const pages = pdfDocs1.getPages();
    pdfDocs1.registerFontkit(fontkit);
    let embeddedFonts = [];
    for (let key in pos) {
      const fontObject = pos[key];

      console.log("FontObject :::: ", fontObject);
      const fontBytes = await fetch(fontObject.fontFile).then((res) =>
        res.arrayBuffer()
      );

      console.log(" fontBytes ::::: ", fontBytes);
      await fontkit.create(fontBytes);
      const embeddedFont = await pdfDocs1.embedFont(fontBytes, {
        subset: true,
      });
      embeddedFonts.push(embeddedFont);

      console.log("embeddedFonts ::::: ", embeddedFonts);
    }

    let index = 0;
    for (let key in pos) {
      const page = pages[pos[key].page - 1];
      const { height } = page.getSize();
      const textHeight = embeddedFonts[index].sizeAtHeight(pos[key]?.size);
      let x = pos[key].x;

      console.log("COLOR HHAHAHHAHAH:::: ", textHeight);
      page.drawText(data[key].replaceAll(/;/g, ","), {
        x: x,
        y:
          height -
          (pos[key].y +
            textHeight -
            (textHeight >= 46
              ? 7
              : textHeight >= 30
              ? 6
              : textHeight >= 12
              ? 3
              : 1)),
        size: pos[key]?.size,
        font: embeddedFonts[index],
        color: rgb(
          pos[key]?.color[0] / 255,
          pos[key]?.color[1] / 255,
          pos[key]?.color[2] / 255
        ),
      });
      index++;
    }
    const pdfBytes = await pdfDocs1.save();
    return pdfBytes;
  };

  // Download Failed Campaigns (PDFs or Images)
  const downloadFailedZip = async (failedList, type) => {
    if (!failedList || failedList.length === 0) {
      alert("No failed campaigns to download");
      return;
    }
    // setLoading(true);

    const zip = new JSZip();

    for (let i = 0; i < failedList.length; i++) {
      const entry = failedList[i];
      // console.log("entry", entry);

      const url = entry?.campaign?.template_url;
      // console.log("url :: :: ", url);

      if (url.endsWith(".pdf")) {
        // Process PDF
        try {
          const pdfBytes = await changeFont({
            ...entry?.variables,
            name: entry?.name,
          });

          // const fileName = `${entry.name || `failed-${i + 1}`}.pdf`;
          const fileName =
            type === "failed"
              ? `${entry.name || `failed-${i + 1}`}.pdf`
              : `${entry.name || `invalid-${i + 1}`}.pdf`;
          zip.file(fileName, pdfBytes);
        } catch (error) {
          console.error("Error processing PDF:", error);
        }
      } else if (isImageFile(url)) {
        // Process Image
        try {
          // Check if the entry contains an array of image URLs
          const imageUrls = Array.isArray(entry.imageUrls)
            ? entry.imageUrls
            : [url];

          // Get API base URL from environment variable
          const apiUrl = process.env.REACT_APP_API_URL;

          // console.log("imageUrls", imageUrls);

          // Loop through each image URL
          for (let j = 0; j < imageUrls.length; j++) {
            const imageUrl = `${apiUrl}/storage/${imageUrls[j]}`;
            // console.log("imageUrl", imageUrl);

            const imageResponse = await fetch(imageUrl);
            // console.log("imageResponse", imageResponse?.url);

            if (!imageResponse.ok) {
              console.error(`Failed to fetch image from URL: ${imageUrl}`);
              continue; // Skip this image and proceed to the next
            }

            const imageBlob = await imageResponse.blob();
            // console.log("imageBlob", imageBlob);

            const fileExtension = url.split(".").pop();
            // console.log("fileExtension", fileExtension);

            const fileName =
              type === "failed"
                ? `${entry.name || `failed-${i + 1}`}.${fileExtension}`
                : `${entry.name || `invalid-${i + 1}`}.${fileExtension}`;
            // console.log("fileName", fileName);

            zip.file(fileName, imageBlob);
          }
        } catch (error) {
          console.error("Error processing image:", error);
        }
      } else {
        console.warn(`Unsupported file type for entry: ${entry.name}`);
      }
    }

    // Generate the ZIP file and download it
    try {
      const content = await zip.generateAsync({ type: "blob" });
      saveAs(
        content,
        type === "failed" ? "failed-list.zip" : "invalid-list.zip"
      );
      setLoading(false);
    } catch (error) {
      console.error("Error generating ZIP:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
    // zip.generateAsync({ type: "blob" }).then((content) => {
    //   saveAs(
    //     content,
    //     type === "failed" ? "failed-list.zip" : "invalid-list.zip"
    //   );
    // setLoading(false);
    // });
  };

  const showBtnIfArrHasPdfOrImg =
    Array.isArray(campaingDetailsData?.data) &&
    campaingDetailsData.data.length > 0 &&
    campaingDetailsData.data.some(
      (item) =>
        item.campaign?.template_type === "pdf" ||
        item.campaign?.template_type === "image"
    );

  const handleChange = (newValue) => {
    if (newValue) {
      // Update state with both value and label
      setLimit({ value: newValue.value, label: newValue.label });
    } else {
      // If the value is null (when user clears selection), set a default value
      setLimit({ value: 50, label: 50 }); // Set to default object
    }
  };

  // console.log("campaingDetailsData", campaingDetailsData?.data);
  // console.log("campaingDetails : : ", campaingDetails);

  return (
    <CommonLayout titles={titles}>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{snackbarMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Container fluid className="px-0">
        <BackPageTab
          to="#"
          onClick={() => handleBackClick()}
          icon={
            <ArrowLeftIcon
              width={22}
              height={22}
              color="#444050"
              strokeWidth="2.2"
            />
          }
          title="Campaigns Details"
        />
        <Row
          className="mt-4"
          // onClick={handleClickOutside}
        >
          <Col xl={12} xxl={4}>
            <div className="grid gap-4 mb-4 mb-xxl-0">
              <Card className="rounded-2 g-col-12 g-col-lg-6 g-col-xxl-12 p-4 border-0 common-shadow w-100 common-card">
                <Card.Header className="px-0 pt-0 pb-4 bg-transparent border-0 common-card-header">
                  <div className="d-flex justify-content-between align-items-center gap-3">
                    <h5 className="fw-medium text-capitalize">
                      Customer Details
                    </h5>
                  </div>
                </Card.Header>
                <Card.Body className="p-0 common-card-body">
                  <Link
                    to=""
                    className="d-flex align-items-center gap-2 text-decoration-none mb-4 card-profile"
                  >
                    <div className=" rounded-circle d-flex align-items-center justify-content-center flex-shrink-1 user-icon">
                      <span className="text-uppercase fw-normal">
                        {getUserInitial(campaingDetails?.client_event?.name)}
                      </span>
                    </div>
                    <h6 className="fw-medium ms-1 text-capitalize">
                      {campaingDetails.client_event?.name}
                    </h6>
                  </Link>
                  <div className="d-flex flex-column gap-3 card-content">
                    <div>
                      <h6 className=" fw-medium mb-1 text-capitalize">
                        Contact info
                      </h6>
                      <p className="mb-1 fw-normal">
                        Email: {campaingDetails.client_event?.email}
                      </p>
                      <p className=" fw-normal">
                        Mobile: {campaingDetails.client_event?.contact_number}
                      </p>
                    </div>
                  </div>
                </Card.Body>
              </Card>
              <Card className="rounded-2 g-col-12 g-col-lg-6 g-col-xxl-12 p-4 border-0 common-shadow w-100 common-card">
                <Card.Header className="px-0 pt-0 pb-4 bg-transparent border-0 common-card-header">
                  <div>
                    <h5 className="fw-medium text-capitalize mb-1">
                      Campaigns Details
                    </h5>
                  </div>
                </Card.Header>
                <Card.Body className="p-0 common-card-body">
                  <div className="d-flex flex-column gap-3 card-content  mb-3 mb-xxl-4">
                    <div>
                      <h6 className=" fw-medium mb-1 text-capitalize">
                        Campaigns Name
                      </h6>
                      <p className=" fw-normal">{campaingDetails.name}</p>
                    </div>
                  </div>
                  <div className="grid row-gap-3 column-gap-0 column-gap-xxl-2 mb-3 mb-xxl-4">
                    <div className="g-col-12 g-col-xl-6">
                      <div className="d-flex align-items-center status-content primary-status">
                        <div className=" rounded-2 d-flex align-items-center justify-content-center status-icon">
                          <SentIcon
                            width={28}
                            height={28}
                            color="#7367f0"
                            strokeWidth="1.5"
                          />
                        </div>
                        <div>
                          <h6 className="fw-medium text-capitalize">Status</h6>
                          <span className="text-capitalize">
                            {campaingDetails.status}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="g-col-12 g-col-xl-6">
                      <div className="d-flex align-items-center status-content primary-status">
                        <div className=" rounded-2 d-flex align-items-center justify-content-center status-icon">
                          <UserIcon
                            width={28}
                            height={28}
                            color="#7367f0"
                            strokeWidth="1.5"
                          />
                        </div>
                        <div>
                          <h6 className="fw-medium text-capitalize">
                            Recipient
                          </h6>
                          <span className="text-capitalize">
                            {" "}
                            {activeSubTab === "guests"
                              ? campaingDetailsData?.total_non_tested
                              : campaingDetailsData?.total_tested || 0}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="grid row-gap-3 column-gap-0 column-gap-xxl-2 card-content mb-4">
                    <div className="g-col-12 g-col-xl-6">
                      <h6 className=" fw-medium mb-1 text-capitalize">
                        Delay From
                      </h6>
                      <div className="d-flex align-items-center gap-2">
                        <p>
                          {/* <ArrowRightIcon
                            width={16}
                            height={16}
                            color="#7367f0"
                            strokeWidth="2"
                          /> */}
                        </p>
                        <p className="fw-normal margin-top-4">
                          {campaingDetails?.delay_second_from
                            ? campaingDetails?.delay_second_from
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="g-col-12 g-col-xl-6">
                      <h6 className=" fw-medium mb-1 text-capitalize">
                        Delay To
                      </h6>
                      <div className="d-flex align-items-center gap-2">
                        <p>
                          {/* <Calendar03Icon
                            width={16}
                            height={16}
                            color="#7367f0"
                            strokeWidth="2"
                          /> */}
                        </p>
                        <p className="fw-normal margin-top-4">
                          {campaingDetails?.delay_second_to
                            ? campaingDetails?.delay_second_to
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="grid row-gap-3 column-gap-0 column-gap-xxl-2 card-content">
                    <div className="g-col-12 g-col-xl-6">
                      <h6 className=" fw-medium mb-1 text-capitalize">
                        Created On
                      </h6>
                      <p className="fw-normal">
                        {moment(campaingDetails.created_at).format(
                          "DD/MM/YYYY hh:mm A"
                        )}
                      </p>
                    </div>
                    <div className="g-col-12 g-col-xl-6">
                      <h6 className=" fw-medium mb-1 text-capitalize">
                        Updated On
                      </h6>
                      <p className="fw-normal">
                        {moment(campaingDetails.updated_at).format(
                          "DD/MM/YYYY hh:mm A"
                        )}
                      </p>
                    </div>
                  </div>
                </Card.Body>
              </Card>

              {campaingDetails &&
                campaingDetails?.status &&
                campaingDetails?.status === "scheduled" && (
                  <Card className="rounded-2 g-col-12 g-col-lg-6 g-col-xxl-12 p-4 border-0 common-shadow w-100 common-card">
                    <Card.Header className="px-0 pt-0 pb-4 bg-transparent border-0 common-card-header">
                      <div>
                        <h5 className="fw-medium text-capitalize mb-1">
                          Schedule Details
                        </h5>
                      </div>
                    </Card.Header>
                    <Card.Body className="p-0 common-card-body">
                      <div className="grid row-gap-3 column-gap-0 column-gap-xxl-2 card-content">
                        <div className="g-col-12 g-col-xl-6">
                          <h6 className=" fw-medium mb-1 text-capitalize">
                            Schedule Date & Time
                          </h6>
                          <div className="d-flex align-items-center gap-2">
                            <p>
                              <Calendar03Icon
                                width={16}
                                height={16}
                                color="#7367f0"
                                strokeWidth="2"
                              />
                            </p>
                            <p className="fw-normal margin-top-4">
                              {campaingDetails?.scheduler_date
                                ? moment(
                                    campaingDetails?.scheduler_date
                                  ).format("DD-MM-YYYY hh:mm A")
                                : "-"}
                            </p>
                          </div>
                        </div>
                        <div className="g-col-12 g-col-xl-6">
                          <h6 className=" fw-medium mb-1 text-capitalize">
                            Sent Date & Time
                          </h6>
                          <div className="d-flex align-items-center gap-2">
                            <p>
                              <Calendar03Icon
                                width={16}
                                height={16}
                                color="#7367f0"
                                strokeWidth="2"
                              />
                            </p>
                            <p className="fw-normal margin-top-4">
                              {campaingDetails?.scheduler_date
                                ? moment(
                                    campaingDetails?.scheduler_date
                                  ).format("DD-MM-YYYY hh:mm A")
                                : "-"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                )}

              <Card className="rounded-2 g-col-12 g-col-lg-6 g-col-xxl-12 p-4 border-0 common-shadow w-100 common-card">
                <Card.Header className="px-0 pt-0 pb-4 bg-transparent border-0 common-card-header">
                  <div>
                    <h5 className="fw-medium text-capitalize mb-1">Message</h5>
                  </div>
                </Card.Header>
                <Card.Body className="p-0 common-card-body">
                  {campaingDetails.message ? (
                    <div className="d-flex flex-column gap-3 card-content mb-3 mb-xxl-4">
                      <p
                        className="fw-normal"
                        style={{ whiteSpace: "pre-wrap" }}
                      >
                        {campaingDetails.message}
                      </p>
                    </div>
                  ) : (
                    <div className="text-center w-100 blank-table-view">
                      <h4>No message available</h4>
                    </div>
                  )}
                </Card.Body>
              </Card>

              <Card className="rounded-2 g-col-12 g-col-lg-6 g-col-xxl-12 p-4 border-0 common-shadow w-100 common-card">
                <Card.Header className="px-0 pt-0 pb-4 bg-transparent border-0 common-card-header">
                  <div>
                    <h5 className="fw-medium text-capitalize mb-1">PDF</h5>
                  </div>
                </Card.Header>
                <Card.Body className="p-0 common-card-body">
                  {documentUrl ? (
                    <div className="d-flex align-items-center justify-content-center gap-3 card-content">
                      <div
                        className={`${documentUrl ? "pdf-view border" : null}`}
                      >
                        {campaingDetails?.template_type === "pdf" ? (
                          <>
                            <div
                              onClick={() => handleOpenPdfView()}
                              style={{ cursor: "pointer" }}
                            >
                              <Document
                                file={documentUrl}
                                onLoadSuccess={onDocumentLoadSuccess}
                                onLoadError={(error) =>
                                  console.log(
                                    "Error while loading document:",
                                    error
                                  )
                                }
                                onSourceError={(error) =>
                                  console.log(
                                    "Error while fetching document source:",
                                    error
                                  )
                                }
                                className="h-100 w-100"
                              >
                                <Page
                                  width={400}
                                  className="h-100 w-100"
                                  pageNumber={pdfCurrentPage}
                                  renderTextLayer={false}
                                  renderAnnotationLayer={false}
                                />
                                {/* {Array.from(new Array(numPages), (el, index) => (
                                    <Page
                                      key={`page_${index + 1}`}
                                      className={`h-100 w-100 ${isZoomed ? "shadow-xl" : ""}`}
                                      width={isZoomed ? 800 : 400} // Adjust width for zoomed/non-zoomed state
                                      pageNumber={isZoomed ? index + 1 : pdfCurrentPage} // Display current page in non-zoomed view
                                      renderTextLayer={false}
                                      renderAnnotationLayer={false}
                                    />
                                  ))} */}
                              </Document>
                            </div>
                            {/* </div>
                            </div> */}
                          </>
                        ) : campaingDetails?.template_type?.startsWith(
                            "image"
                          ) ? (
                          <Zoom>
                            <img
                              src={documentUrl}
                              alt="Campaign Document"
                              style={{ maxWidth: "100%" }}
                            />
                          </Zoom>
                        ) : (
                          <p>Unsupported document type</p>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="text-center w-100 blank-table-view">
                      <h4>No PDF available</h4>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </div>
          </Col>
          <Col xl={12} xxl={8}>
            <div className="px-0 grid organization-list-content mb-4">
              <div
                className={`g-col-12 g-col-lg-6 g-col-xl-4 p-4 rounded-2 common-shadow cursor-pointer ${
                  activeTabStatus === "all" ? "box-color" : "bg-white"
                }`}
                onClick={() => handleStatusClick("all")}
              >
                <div className={`organization-widget border-end-0`}>
                  <Widget
                    count={
                      activeSubTab === "guests"
                        ? campaingDetailsData?.total_non_tested
                        : campaingDetailsData?.total_tested || 0
                    }
                    title="Total Recipients"
                    icon={
                      <Message01Icon
                        width={26}
                        height={26}
                        color="#444050"
                        strokeWidth="1.5"
                      />
                    }
                    className="w-100 "
                  />
                </div>
              </div>
              <div
                className={`g-col-12 g-col-lg-6 g-col-xl-4 p-4 rounded-2 common-shadow cursor-pointer box-color ${
                  activeTabStatus === "pending" ? "box-color" : "bg-white"
                }`}
                onClick={() => handleStatusClick("pending")}
              >
                <div className={`organization-widget border-end-0`}>
                  <Widget
                    count={campaingDetailsData?.total_pending || 0}
                    title="In Queue"
                    icon={
                      <MessageSearch01Icon
                        width={26}
                        height={26}
                        color="#444050"
                        strokeWidth="1.5"
                      />
                    }
                    className="w-100 "
                  />
                </div>
              </div>
              <div
                className={`g-col-12 g-col-lg-6 g-col-xl-4 p-4 rounded-2 common-shadow cursor-pointer ${
                  activeTabStatus === "sent" ? "box-color" : "bg-white"
                }`}
                onClick={() => handleStatusClick("sent")}
              >
                <div className={`organization-widget border-end-0`}>
                  <Widget
                    count={campaingDetailsData?.total_sent || 0}
                    title="Sent"
                    icon={
                      <SentIcon
                        width={26}
                        height={26}
                        color="#444050"
                        strokeWidth="1.5"
                      />
                    }
                    className="w-100 "
                  />
                </div>
              </div>
              <div
                className={`g-col-12 g-col-lg-6 g-col-xl-4 p-4 rounded-2 common-shadow cursor-pointer ${
                  activeTabStatus === "delivered" ? "box-color" : "bg-white"
                }`}
                onClick={() => handleStatusClick("delivered")}
              >
                <div className={`organization-widget border-end-0`}>
                  <Widget
                    count={campaingDetailsData?.total_delivered || 0}
                    title="Delivered"
                    icon={
                      <MessageDone01Icon
                        width={26}
                        height={26}
                        color="#444050"
                        strokeWidth="1.5"
                      />
                    }
                    className="w-100 "
                  />
                </div>
              </div>
              <div
                className={`g-col-12 g-col-lg-6 g-col-xl-4 p-4 rounded-2 common-shadow cursor-pointer ${
                  activeTabStatus === "seen" ? "box-color" : "bg-white"
                }`}
                onClick={() => handleStatusClick("seen")}
              >
                <div className={`organization-widget border-end-0`}>
                  <Widget
                    count={campaingDetailsData?.total_read || 0}
                    title="Seen"
                    icon={
                      <MessagePreview01Icon
                        width={26}
                        height={26}
                        color="#444050"
                        strokeWidth="1.5"
                      />
                    }
                    className="w-100 "
                  />
                </div>
              </div>
              <div
                className={`g-col-12 g-col-lg-6 g-col-xl-4 p-4 rounded-2 common-shadow cursor-pointer ${
                  activeTabStatus === "failed" ? "box-color" : "bg-white"
                }`}
                onClick={() => handleStatusClick("failed")}
              >
                <div className={`organization-widget border-end-0`}>
                  <Widget
                    count={campaingDetailsData?.total_failed || 0}
                    title="Failed"
                    icon={
                      <MessageCancel01Icon
                        width={26}
                        height={26}
                        color="#444050"
                        strokeWidth="1.5"
                      />
                    }
                    className="w-100 "
                  />
                </div>
              </div>
              <div
                className={`g-col-12 g-col-lg-6 g-col-xl-4 p-4 rounded-2 common-shadow cursor-pointer ${
                  activeTabStatus === "invalid" ? "box-color" : "bg-white"
                }`}
                onClick={() => handleStatusClick("invalid")}
              >
                <div className={`organization-widget border-end-0`}>
                  <Widget
                    count={campaingDetailsData?.total_invalid || 0}
                    title="Invalid"
                    icon={
                      <MessageQuestionIcon
                        width={26}
                        height={26}
                        color="#444050"
                        strokeWidth="1.5"
                      />
                    }
                    className="w-100 "
                  />
                </div>
              </div>
            </div>
            <div className="mb-4">
              <NavTabs
                defaultActiveKey={activeSubTab}
                id="uncontrolled-tab-example"
                onSelect={handleSubTabSelect}
                tabsData={CampaignsDataTabs}
              />
            </div>
            <div className="bg-white rounded-2 common-shadow custom-style-3">
              <div className="p-4 d-flex align-items-center justify-content-between gap-4">
                <Input
                  type="search"
                  value={searchTerm}
                  placeholder="Search"
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setCurrentPage(0);
                  }}
                  className="shadow-none base-search"
                />

                <div className="d-flex align-items-center justify-content-center gap-2 flex-wrap">
                  {activeTabStatus === "invalid" && showBtnIfArrHasPdfOrImg && (
                    <CommomButton
                      icon={
                        <FileDownloadIcon
                          width={15}
                          height={15}
                          color="#7367F0"
                          strokeWidth="2.5"
                        />
                      }
                      title="Download Invalid"
                      className="btn-outline-primary bg-transparent cursor"
                      onClick={() =>
                        downloadFailedZip(campaingDetailsData?.data, "invalid")
                      }
                      disabled={campaingDetailsData?.data?.length === 0}
                    />
                  )}

                  {activeTabStatus === "failed" && showBtnIfArrHasPdfOrImg && (
                    <CommomButton
                      icon={
                        <FileDownloadIcon
                          width={15}
                          height={15}
                          color="#7367F0"
                          strokeWidth="2.5"
                        />
                      }
                      title="Download Failed"
                      className="btn-outline-primary bg-transparent cursor"
                      onClick={() =>
                        downloadFailedZip(campaingDetailsData?.data, "failed")
                      }
                      disabled={campaingDetailsData?.data?.length === 0}
                    />
                  )}

                  {isFailedSelected && (
                    // <Button
                    //   variant="danger"
                    //   onClick={handleResendFailedClick}
                    //   disabled={campaingDetailsData?.data?.length === 0}
                    // >
                    //   Resend All Failed
                    // </Button>
                    <CommomButton
                      onClick={() => handleResendFailedClick()}
                      // icon={
                      //   <MessageUpload01Icon
                      //     width={17}
                      //     height={17}
                      //     color="#ffffff"
                      //     strokeWidth="2.5"
                      //   />
                      // }
                      title="Resend"
                      className="btn-primary common-one-shadow"
                      subClassName="gap-1"
                      disabled={campaingDetailsData?.data?.length === 0}
                    />
                  )}

                  {isInvalidSelected && (
                    // <Button
                    //   variant="danger"
                    //   onClick={handleResendFailedClick}
                    //   disabled={campaingDetailsData?.data?.length === 0}
                    // >
                    //   Resend All Failed
                    // </Button>
                    <CommomButton
                      onClick={() => handleOpenModal()}
                      // icon={
                      //   <MessageUpload01Icon
                      //     width={17}
                      //     height={17}
                      //     color="#ffffff"
                      //     strokeWidth="2.5"
                      //   />
                      // }
                      title="New"
                      className="btn-primary common-one-shadow"
                      subClassName="gap-1"
                      disabled={campaingDetailsData?.data?.length === 0}
                    />
                  )}

                  <CommomButton
                    onClick={() => handleExportClick()}
                    icon={
                      <MessageUpload01Icon
                        width={17}
                        height={17}
                        color="#ffffff"
                        strokeWidth="2.5"
                      />
                    }
                    title="Export"
                    className="btn-primary common-one-shadow mx-2"
                    subClassName="gap-1"
                  />
                  <CommomButton
                    onClick={() => handleRefreshClick()}
                    icon={
                      <ReloadIcon
                        width={17}
                        height={17}
                        color="#ffffff"
                        strokeWidth="2.5"
                      />
                    }
                    title="Refresh"
                    className="btn-primary common-one-shadow"
                    subClassName="gap-1"
                  />
                </div>
              </div>
              <Table responsive className="common-table">
                <thead>
                  <tr>
                    <th>
                      <p>Id</p>
                    </th>

                    {/* <th>
                      <p>Failed Reason</p>
                    </th> */}

                    <th>
                      <p>Name</p>
                    </th>

                    <th>
                      <p>Mobile</p>
                    </th>

                    {/* <th>
                      <p>Date</p>
                    </th> */}

                    {/* <th>
                      <p>Schedule Date</p>
                    </th> */}

                    <th>
                      <p>Status</p>
                    </th>

                    {/* <th>
                      <p>Credits Used</p>
                    </th> */}

                    <th>
                      <p>Sent Status</p>
                    </th>

                    <th>
                      <p>Delivery Status</p>
                    </th>

                    <th>
                      <p>Seen Status</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {campaingDetailsData?.data?.length === 0 ? (
                    <tr>
                      <td
                        colSpan="7"
                        className="text-center"
                        // style={{position: "relative", left: "330px"}}
                        // colSpan={getColumnsForActiveTab(activeTab).length + 1}
                      >
                        <div className="text-center w-100 blank-table-view">
                          <Image
                            src="/assets/images/component-common/blank-table.png"
                            alt="Blank-Table-Data-Image"
                            className="img-fluid"
                          />
                          <div className="mt-3">
                            <h5 className="fw-medium">
                              Couldn't find any data.
                            </h5>
                            <p className="pt-2 mt-1">
                              This report does not contain any points. Try to
                              change your filter and try again.
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    campaingDetailsData?.data?.map((event, i) => {
                      return (
                        <tr key={i}>
                          <td className="id d-flex">
                            <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1 mx-2">
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <TT id="tooltip-top">
                                    {event?.failed_reason
                                      ? event?.failed_reason
                                      : event?.status}
                                  </TT>
                                }
                              >
                                {event?.status === "pending" ? (
                                  <div
                                    class="spinner-border text-warning"
                                    role="status"
                                    style={{ width: "15px", height: "15px" }}
                                  ></div>
                                ) : (
                                  <Image
                                    src={
                                      event?.status === "failed"
                                        ? "/images/table-icon/failed.svg"
                                        : event?.status === "sent"
                                        ? "/images/table-icon/Send.svg"
                                        : event?.status === "read"
                                        ? "/images/table-icon/eye.svg"
                                        : event?.status === "delivered"
                                        ? "/images/table-icon/check.svg"
                                        : event?.status === "invalid"
                                        ? "/images/table-icon/invalid.svg"
                                        : "/images/modal-icon/info-icon.svg"
                                    }
                                    color={"#000"}
                                    alt="info-icon"
                                    className="me-1"
                                    width={15}
                                    height={15}
                                  />
                                )}
                              </OverlayTrigger>
                            </div>
                            {event?.order_number
                              ? `#${event?.order_number}`
                              : `#${i + 1 + currentPage * limit?.value}`}
                          </td>
                          {/* <td className="text-capitalize">
                            {event?.failed_reason || "-"}
                          </td> */}
                          <td className="text-capitalize">{event?.name}</td>
                          <td>{event?.mobile_number}</td>
                          {/* <td>
                            {new Date(
                              event?.campaign.created_at
                            ).toLocaleString("en-IN", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                              hour: "2-digit",  
                              minute: "2-digit",
                              hour12: true,
                            })}
                          </td> */}
                          {/* <td>
                            {event?.campaign.scheduler_date
                              ? moment(event?.campaign?.scheduler_date).format(
                                  "DD/MM/YYYY hh:mm A"
                                )
                              : "-"}
                          </td> */}
                          <td className="text-capitalize">
                            {event?.status !== "pending" ? (
                              <StatusChip
                                bg="chip-ornage"
                                title={event.status}
                                className="text-capitalize"
                              />
                            ) : (
                              <div>-</div>
                            )}
                          </td>

                          {/* <td>{event?.credits}</td> */}
                          <td>
                            {event?.whatsapp_sent
                              ? new Date(
                                  event?.whatsapp_sent * 1000
                                ).toLocaleString("en-IN", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                  hour12: true,
                                })
                              : "-"}
                          </td>
                          <td>
                            {event?.whatsapp_deliver
                              ? new Date(
                                  event?.whatsapp_deliver * 1000
                                ).toLocaleString("en-IN", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                  hour12: true,
                                })
                              : "-"}
                          </td>
                          <td>
                            {event?.whatsapp_seen
                              ? new Date(
                                  event?.whatsapp_seen * 1000
                                ).toLocaleString("en-IN", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                  hour12: true,
                                })
                              : "-"}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table>

              <div className="p-3 mx-1 d-flex flex-wrap align-items-center justify-content-between gap-4">
                <p className="custom-table-infotext ">
                  Displaying {currentPage * limit?.value + 1} to{" "}
                  {Math.min((currentPage + 1) * limit?.value, total)} of {total}{" "}
                  Customers
                </p>
                <div className="d-flex gap-5">
                  {totalPages > 1 ? (
                    <>
                      <CreatableSelect
                        isClearable
                        options={recordsPerPageOptions}
                        value={limit}
                        onChange={handleChange}
                        placeholder="Select or create"
                        menuPortalTarget={document.body} // Render the menu at the body level
                        menuPlacement="top" // Display the options above the input
                        styles={{
                          control: (base) => ({
                            ...base,
                            width: "170px", // Fixed width for the input field
                          }),
                          menu: (base) => ({
                            ...base,
                            width: "170px", // Fixed width for the dropdown menu
                          }),
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999, // Ensure it appears above everything
                          }),
                        }}
                      />

                      <CommonPagination
                        totalItems={total}
                        itemsPerPage={limit?.value}
                        currentPage={currentPage + 1}
                        onPageChange={handlePageChange}
                      />
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </Col>

          {/* <Col lg={12} md={12} xs={12}>
            <div>
              <div className="d-flex align-items-center gap-2 primary-pg-title">
                <div
                  className="d-flex align-items-center justify-content-center rounded-circle back-arrow"
                  onClick={() => handleBackClick()}
                >
                  <ArrowLeft
                    size={24}
                    color="#FFFFFF"
                    weight="bold"
                    className="cursor-pointer"
                  />
                </div>

                <h3 className="mb-0 fw-semibold text-white pg-title fw-medium">
                  Campaign Details
                </h3>
              </div>
            </div>
          </Col>
          <Col xl={12} lg={12} md={12} xs={12} className="mt-6">
            <Tabs
              activeKey={activeTab}
              className="pb-0 table-tabs-items"
              id="uncontrolled-tab-example"
              onSelect={handleTabSelect}
            >
              <Tab
                eventKey="list"
                title={
                  <>
                    <div className="d-flex align-items-center gap-2">
                      <p className="mb-0">List</p>
                    </div>
                  </>
                }
              >
                <div className="tablecontainer">
                  <div
                    className={`mb-3 d-flex ${
                      isFailedSelected
                        ? "justify-content-between"
                        : "justify-content-end"
                    }`}
                  >
                    {isFailedSelected && (
                      <Button
                        variant="danger"
                        onClick={handleResendFailedClick}
                        disabled={campaingDetailsData?.data?.length === 0}
                      >
                        Resend All Failed
                      </Button>
                    )}
                    <div className=" ">
                      <Button
                        variant="primary"
                        className="me-2"
                        onClick={() => handleExportClick()}
                        disabled={
                          campaingDetailsData.total_non_tested === 0
                            ? true
                            : false
                        }
                      >
                        Export
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => handleRefreshClick()}
                        className="me-2"
                      >
                        <RefreshCcw size={15} className="align-middle me-0" />
                      </Button>
                    </div>
                  </div>

                  <Row className="my-5 g-4 cerdit-logs-content">
                    <Col xxl={2} xl={4} lg={4} md={6} xs={12}>
                      <div
                        className="p-4 rounded-3 cedit-h-card h-100"
                        onClick={() => handleStatusClick("all")}
                      >
                        <div className="d-flex align-items-start justify-content-between gap-3">
                          <div>
                            <p className="mb-0 text-capitalize">
                              Total Recipients
                            </p>
                            <span>
                              {activeSubTab === "guests"
                                ? campaingDetailsData?.total_non_tested
                                : campaingDetailsData?.total_tested || 0}
                            </span>
                          </div>
                          <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                        </div>
                      </div>
                    </Col>
                    <Col xxl={2} xl={4} lg={4} md={6} xs={12}>
                      <div
                        className="p-4 rounded-3 cedit-h-card  h-100"
                        onClick={() => handleStatusClick("pending")}
                      >
                        <div className="d-flex align-items-start justify-content-between gap-3">
                          <div>
                            <p className="text-capitalize">In Queue</p>
                            <span>
                              {campaingDetailsData?.total_pending || 0}
                            </span>
                          </div>
                          <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                        </div>
                      </div>
                    </Col>
                    <Col xxl={2} xl={4} lg={4} md={6} xs={12}>
                      <div
                        className="p-4 rounded-3 cedit-h-card  h-100"
                        onClick={() => handleStatusClick("sent")}
                      >
                        <div className="d-flex align-items-start justify-content-between gap-3">
                          <div>
                            <p className="text-capitalize">Sent</p>
                            <span>{campaingDetailsData?.total_sent || 0}</span>
                          </div>
                          <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                        </div>
                      </div>
                    </Col>
                    <Col xxl={2} xl={4} lg={4} md={6} xs={12}>
                      <div
                        className="p-4 rounded-3 cedit-h-card  h-100"
                        onClick={() => handleStatusClick("delivered")}
                      >
                        <div className="d-flex align-items-start justify-content-between gap-3">
                          <div>
                            <p className="text-capitalize">Delivered</p>
                            <span>
                              {campaingDetailsData?.total_delivered || 0}
                            </span>
                          </div>
                          <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                        </div>
                      </div>
                    </Col>
                    <Col xxl={2} xl={4} lg={4} md={6} xs={12}>
                      <div
                        className="p-4 rounded-3 cedit-h-card  h-100"
                        onClick={() => handleStatusClick("seen")}
                      >
                        <div className="d-flex align-items-start justify-content-between gap-3">
                          <div>
                            <p className="text-capitalize">Seen</p>
                            <span>{campaingDetailsData?.total_read || 0}</span>
                          </div>
                          <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                        </div>
                      </div>
                    </Col>
                    <Col xxl={2} xl={4} lg={4} md={6} xs={12}>
                      <div
                        className="p-4 rounded-3 cedit-h-card  h-100"
                        onClick={() => handleStatusClick("failed")}
                      >
                        <div className="d-flex align-items-start justify-content-between gap-3">
                          <div>
                            <p className="text-capitalize">Failed</p>
                            <span>
                              {campaingDetailsData?.total_failed || 0}
                            </span>
                          </div>
                          <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                        </div>
                      </div>
                    </Col>
                    <Col xxl={2} xl={4} lg={4} md={6} xs={12}>
                      <div
                        className="p-4 rounded-3 cedit-h-card  h-100"
                        onClick={() => handleStatusClick("invalid")}
                      >
                        <div className="d-flex align-items-start justify-content-between gap-3">
                          <div>
                            <p className="text-capitalize">Invalid</p>
                            <span>
                              {campaingDetailsData?.total_invalid || 0}
                            </span>
                          </div>
                          <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row className="table-tabs">
                    <Tabs
                      activeKey={activeSubTab}
                      className="pb-0 table-tabs-items"
                      id="uncontrolled-subtab-example"
                      onSelect={handleSubTabSelect}
                    >
                      <Tab
                        eventKey="guests"
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              <p className="mb-0">Guests</p>
                            </div>
                          </>
                        }
                      ></Tab>
                      <Tab
                        eventKey="test"
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              <p className="mb-0">Test</p>
                            </div>
                          </>
                        }
                      ></Tab>
                    </Tabs>
                  </Row>

                  <div className=" overflow-auto">
                    <Table className="text-nowrap data_list text-start ">
                      <thead className="table-light">
                        <tr>
                          <th scope="col">
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Id</span>
                              <img
                                src={SortImage}
                                alt="Sort"
                                style={{ width: "17px", height: "16px" }}
                              />
                            </div>
                          </th>
                          <th scope="col">
                            <div className="d-flex gap-2 align-items-center">
                              <span className="">Name</span>
                              <img
                                src={SortImage}
                                alt="Sort"
                                style={{ width: "17px", height: "16px" }}
                              />
                            </div>
                          </th>
                          <th scope="col">
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Mobile</span>
                              <img
                                src={SortImage}
                                alt="Sort"
                                style={{ width: "17px", height: "16px" }}
                              />
                            </div>
                          </th>
                          <th scope="col">
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Date</span>
                              <img
                                src={SortImage}
                                alt="Sort"
                                style={{ width: "17px", height: "16px" }}
                              />
                            </div>
                          </th>
                          <th scope="col">
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Schedule Date</span>
                              <img
                                src={SortImage}
                                alt="Sort"
                                style={{ width: "17px", height: "16px" }}
                              />
                            </div>
                          </th>
                          <th scope="col">
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Status</span>
                              <img
                                src={SortImage}
                                alt="Sort"
                                style={{ width: "17px", height: "16px" }}
                              />
                            </div>
                          </th>
                          <th scope="col">
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Failed Reason</span>
                              <img
                                src={SortImage}
                                alt="Sort"
                                style={{ width: "17px", height: "16px" }}
                              />
                            </div>
                          </th>
                          <th scope="col">
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Credits Used</span>
                              <img
                                src={SortImage}
                                alt="Sort"
                                style={{ width: "17px", height: "16px" }}
                              />
                            </div>
                          </th>
                          <th scope="col">
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Sent Status</span>
                              <img
                                src={SortImage}
                                alt="Sort"
                                style={{ width: "17px", height: "16px" }}
                              />
                            </div>
                          </th>
                          <th scope="col">
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Delivery Status</span>
                              <img
                                src={SortImage}
                                alt="Sort"
                                style={{ width: "17px", height: "16px" }}
                              />
                            </div>
                          </th>
                          <th scope="col">
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Seen Status</span>
                              <img
                                src={SortImage}
                                alt="Sort"
                                style={{ width: "17px", height: "16px" }}
                              />
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {campaingDetailsData?.data?.length === 0 ? (
                          <tr>
                            <td
                              className="mt-5 fw-bold text-center fs-5"
                              colSpan="11"
                            >
                              No data available
                            </td>
                          </tr>
                        ) : (
                          campaingDetailsData?.data?.map((event, index) => (
                            <tr key={index}>
                              <td>{index + 1 + currentPage * 10}</td>
                              <td className="text-capitalize">{event?.name}</td>
                              <td>{event?.mobile_number}</td>
                              <td>
                                {new Date(
                                  event?.campaign.created_at
                                ).toLocaleString("en-IN", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: true,
                                })}
                              </td>
                              <td>
                                {event?.campaign.scheduler_date
                                  ? moment(
                                      event?.campaign?.scheduler_date
                                    ).format("DD/MM/YYYY hh:mm A")
                                  : "-"}
                              </td>
                              <td className="text-capitalize">
                                {event?.status !== "pending" ? (
                                  event.status
                                ) : (
                                  <Spinner animation="border" size="sm" />
                                )}
                              </td>
                              <td className="text-capitalize">
                                {event?.failed_reason || "-"}
                              </td>
                              <td>{event?.credits}</td>
                              <td>
                                {event?.whatsapp_sent
                                  ? new Date(
                                      event?.whatsapp_sent * 1000
                                    ).toLocaleString("en-IN", {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    })
                                  : "-"}
                              </td>
                              <td>
                                {event?.whatsapp_deliver
                                  ? new Date(
                                      event?.whatsapp_deliver * 1000
                                    ).toLocaleString("en-IN", {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    })
                                  : "-"}
                              </td>
                              <td>
                                {event?.whatsapp_seen
                                  ? new Date(
                                      event?.whatsapp_seen * 1000
                                    ).toLocaleString("en-IN", {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    })
                                  : "-"}
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </Table>
                    {totalPages > 1 ? (
                      <PaginationComponent
                        totalItems={total}
                        itemsPerPage={limit}
                        currentPage={currentPage + 1}
                        onPageChange={handlePageChange}
                      />
                    ) : null}
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="details"
                title={
                  <>
                    <div className="d-flex align-items-center gap-2">
                      <p className="mb-0">Details</p>
                    </div>
                  </>
                }
              >
                {campaingDetails ? (
                  <Card className="mt-5">
                    <Card.Body>
                      <Row>
                        <Col md={6}>
                          <ListGroup variant="flush">
                            <ListGroupItem>
                              <strong>Campaign Name:</strong>{" "}
                              {campaingDetails.name}
                            </ListGroupItem>
                            <ListGroupItem>
                              <strong>Message:</strong>{" "}
                              {campaingDetails.message}
                            </ListGroupItem>
                            <ListGroupItem>
                              <strong>Status:</strong> {campaingDetails.status}
                            </ListGroupItem>
                            <ListGroupItem>
                              <strong>Created At:</strong>{" "}
                              {moment(campaingDetails.created_at).format(
                                "DD/MM/YYYY hh:mm A"
                              )}
                            </ListGroupItem>
                            <ListGroupItem>
                              <strong>Updated At:</strong>{" "}
                              {moment(campaingDetails.updated_at).format(
                                "DD/MM/YYYY hh:mm A"
                              )}
                            </ListGroupItem>
                            <ListGroupItem>
                              <strong>Customer:</strong>{" "}
                              {campaingDetails.client_event?.name}
                            </ListGroupItem>
                          </ListGroup>
                        </Col>
                        <Col md={6}>
                          <h5 className="mt-4">Document</h5>
                          <div className="document-viewer mt-3">
                            {documentUrl ? (
                              campaingDetails?.template_type === "pdf" ? (
                                <>
                                  <Document
                                    file={documentUrl}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    onLoadError={(error) =>
                                      console.log(
                                        "Error while loading document:",
                                        error
                                      )
                                    }
                                    onSourceError={(error) =>
                                      console.log(
                                        "Error while fetching document source:",
                                        error
                                      )
                                    }
                                    className="d-flex justify-content-center"
                                  >
                                    <Page
                                      pageNumber={pdfCurrentPage}
                                      renderTextLayer={false}
                                      renderAnnotationLayer={false}
                                    />
                                  </Document>
                                  <div className="d-flex justify-content-center mb-3">
                                    <Button
                                      variant="outline-primary"
                                      className="me-2"
                                      onClick={goToPreviousPage}
                                      disabled={pdfCurrentPage === 1}
                                    >
                                      <ChevronLeft />
                                    </Button>
                                    <div className="d-flex align-items-center">
                                      Page {pdfCurrentPage} of {pdfTotalPages}
                                    </div>
                                    <Button
                                      variant="outline-primary"
                                      className="ms-2"
                                      onClick={goToNextPage}
                                      disabled={
                                        pdfCurrentPage === pdfTotalPages
                                      }
                                    >
                                      <ChevronRight />
                                    </Button>
                                  </div>
                                </>
                              ) : campaingDetails?.template_type?.startsWith(
                                  "image"
                                ) ? (
                                <img
                                  src={documentUrl}
                                  alt="Campaign Document"
                                  style={{ maxWidth: "100%" }}
                                />
                              ) : (
                                <p>Unsupported document type</p>
                              )
                            ) : (
                              <p>No document available</p>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                ) : (
                  <Spinner animation="border" size="sm" />
                )}
              </Tab>
            </Tabs>
          </Col> */}
        </Row>

        {/* <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Body>
          {loading ? (
            <Spinner animation="border" />
          ) : campaingDetails.template_type === "pdf" ? (
            <>
              <Document
                file={documentUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                className="h-100 w-100"
              >
                <Page
                  className="h-100 w-100"
                  pageNumber={pdfCurrentPage}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                />
              </Document>
              <div className="d-flex justify-content-center mt-2">
                <Button
                  variant="outline-primary"
                  className="me-2"
                  onClick={goToPreviousPage}
                  disabled={pdfCurrentPage === 1}
                >
                  <ChevronLeft />
                </Button>
                <div className="d-flex align-items-center">
                  Page {pdfCurrentPage} of {pdfTotalPages}
                </div>
                <Button
                  variant="outline-primary"
                  className="ms-2"
                  onClick={goToNextPage}
                  disabled={pdfCurrentPage === pdfTotalPages}
                >
                  <ChevronRight />
                </Button>
              </div>
            </>
          ) : campaingDetails.template_type.startsWith("image") ? (
            <img
              src={documentUrl}
              alt="Campaign Document"
              style={{ maxWidth: "100%", maxHeight: "80vh" }}
            />
          ) : (
            <p>Unsupported document type</p>
          )}
        </Modal.Body>
      </Modal> */}

        <Modal
          show={showAddModal}
          centered
          dialogClassName="common-popup-dialog"
          contentClassName="common-popup-content"
          backdropClassName="common-popup-backdrop"
        >
          {/* <ModalHeader className=" ">
          <h3 className="text-primary mb-0">New Customer</h3>
        </ModalHeader> */}
          <Modal.Body className="common-popup-body">
            <div
              className="position-absolute end-0 z-2 bg-white rounded-1 cursor-pointer d-flex align-items-center justify-content-center  common-popup-colose-btn"
              onClick={handleModalClose}
            >
              <Cancel01Icon
                width={16}
                height={16}
                color="#BCBBC1"
                strokeWidth="2.5"
              />
            </div>
            <div className="mb-4 text-center common-popup-heading">
              <h4 className="text-capitalize mb-2 fw-medium">New Campaign</h4>
              <p>Create new campaign for Invalids</p>
            </div>

            <div className="d-flex align-items-center justify-content-center gap-3 common-popup-btn">
              <CommomButton
                title={loading ? "Adding..." : "Add"}
                type="submit"
                disabled={loading}
                className="btn-primary primary-shadow"
                onClick={handleInvalidClick}
              />
              <CommomButton
                title="Cancel"
                onClick={handleModalClose}
                className="btn-muted"
                disabled={loading}
              />
            </div>
          </Modal.Body>
        </Modal>
        <PdfZoomViewModal
          show={openPdfModalView}
          handleClose={handleClosePdfViewModal}
          pdfPageWiseView={pdfPageWiseView}
          campaingDetails={campaingDetails}
        />
      </Container>
    </CommonLayout>
  );
}
