import React, { useState, useCallback } from "react";
import CommonFormLabel from "../../../components/common-form-label/index";
import Input from "../../../components/input/index";
import ValidationFeedback from "../../../components/validation-feedback/index";
import CommomButton from "../../../components/common-button/index";
import { useDispatch } from "react-redux";
import {
  Modal,
  // Button,
  Toast,
  Form,
  // ModalHeader,
  // ModalBody,
  // ModalFooter,
  // FormLabel,
  ToastContainer,
} from "react-bootstrap";
import { Formik } from "formik";
import { addEvent, fetchEvents } from "../../../store/eventsSlice";
import * as Yup from "yup";
// import axios from "axios";
// import QRCode from "qrcode.react";

import { Cancel01Icon, CheckmarkBadge04Icon } from "../../../icons/icons";

export default function Add({
  show,
  handleClose,
  currentPage,
  setCurrentPage,
  searchTerm,
  limit,
  id,
}) {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("toast-success");
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [qrCode, setQrCode] = useState(null);
  // const [ws, setWs] = useState(null);
  // const [isScanSuccessful, setIsScanSuccessful] = useState(false); // State to track if the QR code scan was successful
  const dispatch = useDispatch();
  // console.log('WS',ws);

  const initialEventState = {
    name: "",
    contact_number: "",
    email: "",
    address: "",
  };

  const eventValidation = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    contact_number: Yup.string().required("Contact Number is required"),
    email: Yup.string().email("Invalid email address").nullable(),
    address: Yup.string().nullable(),
  });

  const handleModalClose = useCallback(() => {
    // setQrCode('')
    handleClose();
  }, [handleClose]);

  // useEffect(() => {
  //   const socket = new WebSocket(process.env.REACT_APP_SOCKET_URL);
  //   socket.onopen = () => {
  //     console.log('WebSocket Client Connected');
  //     const wsId = `ws_${id}`; // Generate or fetch a unique WebSocket ID
  //     socket.send(JSON.stringify({ type: 'REGISTER', id: wsId }));
  //     setWs(socket);
  //   };

  //   socket.onmessage = (message) => {
  //     const data = JSON.parse(message.data);
  //     if (data.status === 'connected') {
  //       setSnackbarMessage("WhatsApp connected successfully");
  //       setSnackbarVariant("toast-success");
  //       setShowSnackbar(true);
  //       setIsScanSuccessful(true); // Enable the "Add" button on successful scan
  //     } else if (data.status === 'disconnected') {
  //       setSnackbarMessage("Failed to connect to WhatsApp");
  //       setSnackbarVariant("toast-danger");
  //       setShowSnackbar(true);
  //       setIsScanSuccessful(false); // Disable the "Add" button on failure
  //     }
  //   };

  //   socket.onclose = () => {
  //     console.log('WebSocket Client Disconnected');
  //   };

  //   return () => {
  //     socket.close();
  //   };
  // }, [id]);

  // const handleConnect = async (mobile_number) => {
  //   try {
  //     const response = await axios.post(`${process.env.REACT_APP_WHATSAPP_API_URL}/api/whatsapp/connect`, {
  //       clientId: mobile_number,
  //       wsId: `ws_${id}`, // Use the same WebSocket ID
  //       userId: id,
  //     });
  //     setQrCode(response.data.qrString);
  //   } catch (error) {
  //     console.error("Error connecting to WhatsApp:", error);
  //     setSnackbarMessage("Failed to connect to WhatsApp");
  //     setSnackbarVariant("toast-danger");
  //     setShowSnackbar(true);
  //   }
  // };

  return (
    <>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{snackbarMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <Modal
        show={show}
        centered
        dialogClassName="common-popup-dialog"
        contentClassName="common-popup-content"
        backdropClassName="common-popup-backdrop"
      >
        <Modal.Body className="common-popup-body">
          <div
            className="position-absolute end-0 z-2 bg-white rounded-1 cursor-pointer d-flex align-items-center justify-content-center  common-popup-colose-btn"
            onClick={handleModalClose}
          >
            <Cancel01Icon
              width={16}
              height={16}
              color="#BCBBC1"
              strokeWidth="2.5"
            />
          </div>
          <div className="mb-4 text-center common-popup-heading">
            <h4 className="text-capitalize mb-2 fw-medium">Add Customer</h4>
            <p>Add new customer</p>
          </div>
          <Formik
            initialValues={initialEventState}
            validationSchema={eventValidation}
            onSubmit={async (
              values,
              { setSubmitting, resetForm, setErrors }
            ) => {
              // if (!isScanSuccessful) {
              //   setSnackbarMessage("Please scan the QR code first");
              //   setSnackbarVariant("toast-danger");
              //   setShowSnackbar(true);
              //   setSubmitting(false);
              //   return;
              // }
              // setSubmitting(true);
              setIsSubmitting(true);

              const formData = new FormData();
              formData.append("name", values.name);
              formData.append("contact_number", values.contact_number);
              formData.append("email", values.email);
              formData.append("address", values.address);

              

              dispatch(addEvent(formData))
                .then((res) => {
                  if (res.payload.success) {
                    // setCurrentPage()
                    dispatch(
                      fetchEvents({
                        offset: currentPage,
                        limit:limit?.value,
                        search: searchTerm,
                        id: id,
                      })
                    );
                    setSnackbarMessage(res.payload.message);
                    setSnackbarVariant("toast-success");
                    setShowSnackbar(true);
                    resetForm();
                    handleModalClose();
                    setIsSubmitting(false);
                  } else {
                    setSnackbarMessage(res.payload.message);
                    setErrors(res.payload.data);
                    setSnackbarVariant("toast-danger");
                    setShowSnackbar(true);
                    setIsSubmitting(false);
                  }
                })
                .catch((error) => {
                  setSnackbarMessage(
                    "Failed to add customer: " + error.message
                  );
                  setSnackbarVariant("toast-danger");
                  setShowSnackbar(true);
                  setIsSubmitting(false);
                })
                .finally(() => {
                  setIsSubmitting(false);
                });
            }}
          >
            {({ errors, touched, handleChange, handleSubmit, values }) => (

              <Form noValidate onSubmit={handleSubmit}>
                {/* {console.log("nbcsddv", values)} */}
                <div className="mb-4">
                  <CommonFormLabel htmlFor="name" title="Name" />
                  <Input
                    name="name"
                    type="text"
                    // value={values.name}
                    placeholder="Enter Your Name"
                    onChange={handleChange}
                    className={`shadow-none ${
                      errors.name && touched.name ? "error" : "correct"
                    }`}
                  />
                  {errors.name && touched.name ? (
                    <ValidationFeedback title={errors.name} />
                  ) : null}
                </div>

                <div className="mb-4">
                  <CommonFormLabel
                    htmlFor="contact_number"
                    title="Contact Number"
                  />
                  <Input
                    name="contact_number"
                    type="number"
                    placeholder="Enter Your Contact No."
                    onChange={handleChange}
                    className={`shadow-none ${
                      errors.contact_number && touched.contact_number
                        ? "error"
                        : "correct"
                    }`}
                  />
                  {errors.contact_number && touched.contact_number ? (
                    <ValidationFeedback title={errors.contact_number} />
                  ) : null}
                </div>

                <div className="mb-4">
                  <CommonFormLabel htmlFor="email" title="Email" />
                  <Input
                    name="email"
                    type="email"
                    placeholder="Enter Your Email"
                    onChange={handleChange}
                    className={`shadow-none ${
                      errors.email && touched.email ? "error" : "correct"
                    }`}
                  />
                  {errors.email && touched.email ? (
                    <ValidationFeedback title={errors.email} />
                  ) : null}
                </div>

                <div className="mb-4">
                  <CommonFormLabel htmlFor="address" title="Address" />
                  <Input
                    name="address"
                    type="text"
                    placeholder="Enter Your Address"
                    onChange={handleChange}
                    className={`shadow-none ${
                      errors.address && touched.address ? "error" : "correct"
                    }`}
                  />
                </div>

                <div className="d-flex align-items-center justify-content-center gap-3 common-popup-btn">
                  <CommomButton
                    title={isSubmitting ? "Adding..." : "Add"}
                    type="submit"
                    disabled={isSubmitting}
                    className="btn-primary primary-shadow"
                  />
                  <CommomButton
                    title="Cancel"
                    onClick={handleModalClose}
                    className="btn-muted"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}
