import React from "react";
import { Dropdown } from "react-bootstrap";

export default function CommonActionDropDown({
  icon,
  firstTitle,
  firstHref,
  secondTitle,
  secondHref,
  actions,
  id,
  as,
  show,
  onToggle,
}) {
  return (
    <Dropdown
      align="end"
      as={as}
      show={show}
      onToggle={onToggle}
      className=" position-static common-action-btn"
    >
      <Dropdown.Toggle
        variant="none"
        className="outline-none border-0 p-0 d-flex align-items-center justify-content-center drop-btn"
        id={id}
      >
        {icon}
      </Dropdown.Toggle>

      <Dropdown.Menu className="border-0 p-2 rounded-2 z-1 action-drop-menu">
        {actions.map((action, index) => {
          return (
            <Dropdown.Item
              key={index}
              onClick={action.handler}
              className="text-capitalize rounded-2 fw-normal action-drop-item"
            >
              {action.title}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
