import React, { useState } from "react";
import CommonFormLabel from "../../../components/common-form-label/index";
import Input from "../../../components/input/index";
import ValidationFeedback from "../../../components/validation-feedback/index";
import CommomButton from "../../../components/common-button/index";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Toast, ToastContainer } from "react-bootstrap";
// import { addCreditRequest  } from "../../../store/customerSlice";
// import { fetchWalletDetails, } from "../../../store/walletSlice";
import { useAuth } from "../../../context/AuthContext";
import { Formik, Form } from "formik";
import "../CreditsLog/style.css";
// import { requestCreditSchema } from "../../../schema/validationSchemas";
// import { CheckCircle } from "react-feather";
import * as Yup from "yup";
import { addCreditRequest } from "../../../store/walletSlice";
import { CheckmarkBadge04Icon, Cancel01Icon } from "../../../icons/icons";

const Credit = ({ show, handleClose }) => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("toast-success");
  const dispatch = useDispatch();
  const { user } = useAuth();
  // console.log("User", user);

  // useEffect(() => {
  //   if (show) {
  //       dispatch(fetchMyCredit(user.id));
  //   }
  // }, [show, dispatch, user.id, limit, currentPage, dateRange]);

  const initialCreditState = {
    credits: "",
    description: "",
  };

  const requestCreditSchema = Yup.object().shape({
    credits: Yup.number()
      .typeError("Credits must be a number")
      .min(1, "Credits must be at least 1")
      .required("Credits are required"),
  });

  //   const getCurrentFormattedDate = () => {
  //     const today = new Date();
  //     const year = today.getFullYear();
  //     const month = (today.getMonth() + 1).toString().padStart(2, "0");
  //     const day = today.getDate().toString().padStart(2, "0");
  //     return `${year}/${month}/${day}`;
  //   };

  const userCredits = useSelector(
    (state) => state.wallet.myCredit.available_credits
  );

  return (
    <>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{snackbarMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        dialogClassName="common-popup-dialog"
        contentClassName="common-popup-content"
        backdropClassName="common-popup-backdrop"
      >
        <Modal.Body className="common-popup-body">
          <div
            className="position-absolute end-0 z-2 bg-white rounded-1 cursor-pointer d-flex align-items-center justify-content-center  common-popup-colose-btn"
            onClick={handleClose}
          >
            <Cancel01Icon
              width={16}
              height={16}
              color="#BCBBC1"
              strokeWidth="2.5"
            />
          </div>
          <div className="mb-4 text-center common-popup-heading">
            <h4 className="text-capitalize mb-2 fw-medium">Request Credit</h4>
            <p>Add Request Credit</p>
          </div>
          <Formik
            initialValues={{
              ...initialCreditState,
              available_credits: userCredits,
            }}
            validationSchema={requestCreditSchema}
            onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
              const formData = new FormData();

              formData.append("credits", values.credits);
              formData.append("description", values.description);
              formData.append("company_id", user.company_id);

              formData.append("_method", "POST");
              dispatch(addCreditRequest(formData))
                .then((res) => {
                  if (res.payload && res.payload.success) {
                    // dispatch(
                    //   fetchWalletDetails({
                    //     id: user.company_id,
                    //     offset: currentPage,
                    //     limit,
                    //     search: "",
                    //     sort_by_key: sortKey,
                    //     sort_by_value: sortOrder,
                    //   }));
                    // refreshData();
                    setSnackbarMessage(res.payload.message);
                    setSnackbarVariant("toast-success");
                    setShowSnackbar(true);
                    resetForm();
                    handleClose();
                  } else {
                    setSnackbarMessage(res.payload.message);
                    setErrors(res.payload.data);
                    setSnackbarVariant("toast-danger");
                    setShowSnackbar(true);
                  }
                })
                .catch((error) => {
                  setSnackbarMessage(
                    "Failed to request credit: " + error.message
                  );
                  setSnackbarVariant("toast-danger");
                  setShowSnackbar(true);
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          >
            {({ values, setFieldValue, isSubmitting, errors, touched }) => (
              <Form encType="multipart/form-data">
                <div>
                  {/* <div className="d-flex align-items-center gap-2 credit-visible mb-3">
                      <span><strong>Available Credits: </strong></span>
                      <p className="mb-0">
                        {userCredits}
                      </p>
                    </div> */}
                  <div className="mb-4">
                    <CommonFormLabel title="Credits" />
                    <Input
                      name="credits"
                      type="number"
                      placeholder="Enter Your Credits"
                      min="0"
                      onChange={(e) => {
                        setFieldValue("credits", e.target.value);
                      }}
                      className={`shadow-none ${
                        errors.credits && touched.credits ? "error" : "correct"
                      }`}
                    />
                    {errors.credits && touched.credits && (
                      <ValidationFeedback title={errors.credits} />
                    )}
                  </div>

                  <div className="mb-4">
                    <CommonFormLabel title="Description" />
                    <Input
                      name="description"
                      type="text"
                      placeholder="Enter Your Description"
                      onChange={(e) => {
                        setFieldValue("description", e.target.value);
                      }}
                      className={`shadow-none ${
                        errors.description && touched.description
                          ? "error"
                          : "correct"
                      }`}
                    />
                    {/* {errors.description && touched.description && (
                      <ValidationFeedback title={errors.description} />
                    )} */}
                  </div>

                  <div className="d-flex align-items-center justify-content-center gap-3 common-popup-btn">
                    <CommomButton
                      title={isSubmitting ? "Requesting..." : "Request"}
                      type="submit"
                      disabled={isSubmitting}
                      className="btn-primary primary-shadow"
                    />
                    <CommomButton
                      title="Cancel"
                      onClick={handleClose}
                      className="btn-muted"
                    />
                    {/* <Button type="submit" variant="primary" disabled={isSubmitting}>
                      
                      {isSubmitting ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          Requesting...
                        </>
                      ) : (
                        "Request"
                      )}
                    </Button> */}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Credit;
